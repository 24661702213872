import React, { useEffect } from "react";
//package
import styled from "styled-components";
import $ from "jquery";

function LoginSuccessModal({success, setSuccess}) {
    // const[modal, setModal] =useState(false);
  useEffect(() => {
    if (success) {
      $("html").addClass("modal-enabled");
    } else {
      $("html").removeClass("modal-enabled");
    }
  }, [success]);
  const handleSuccessModal = () =>{
    setSuccess(!success);
  }
  return (
    <MainContainer>
      {success ? <Overlay onClick={() => handleSuccessModal()}></Overlay> : ""}
      <BackContainer style={{ transform: success && "scale(1,1)" }}>
        <Modal type="textarea">
          <Container>
            <CoverImage>
              <img
                src={require("../../../assets/images/Group.svg").default}
                alt="Tick icon"
              />
            </CoverImage>
            <Text>Verification Successful !</Text>
            <TextLen>
              You are successfully verified, let's get started
            </TextLen>
            <Bottom>
              <Submit
                onClick={() => {
                  handleSuccessModal();
                }}
              >
                Continue
              </Submit>
            </Bottom>
          </Container>
        </Modal>
      </BackContainer>
    </MainContainer>
  );
}
export default LoginSuccessModal;

const BackContainer = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
  right: 0;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
`;
const Overlay = styled.div`
  background: rgba(67, 66, 66, 0.2);
  backdrop-filter: blur(0.5px);
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0px;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 90%;
  max-width: 490px;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  transition: 0.5s;
  z-index: 101;
  border-radius: 5px;
  background: #fff;
  @media all and (max-width: 1280px) {
    width: 520px;
  }
  @media all and (max-width: 1080px) {
    width: 500px;
  }
  @media all and (max-width: 980px) {
    width: 650px;
  }
  @media all and (max-width: 768px) {
    width: 480px;
  }
  @media all and (max-width: 640px) {
    width: 400px;
  }
  @media all and (max-width: 480px) {
    width: 340px;
  }
  @media all and (max-width: 360px) {
    width: 300px;
  }
`;
const MainContainer = styled.div``;
const Container = styled.div`
  padding: 50px;
  @media all and (max-width: 640px) {
    padding: 30px;
  }
  @media all and (max-width: 480px) {
    padding: 15px;
  }
`;

const Text = styled.h3`
  color: #0a0a0a;
  font-size: 20px;
  margin: 0 auto 20px !important;
  text-align: center;
  font-family: "outfit_medium";
  @media all and (max-width: 640px) {
    font-size: 17px;
    margin-bottom: 10px !important;
  }
`;

const Submit = styled.div`
  background: linear-gradient(123.89deg, #0e94f3 -23.5%, #05e8ba 111.92%);
  padding: 12px 50px;
  color: #fff !important;
  :hover {
    color: #fff !important;
  }
  cursor: pointer;
  @media all and (max-width: 640px) {
    padding: 9px 25px;
    font-size: 15px;
  }
  @media all and (max-width: 480px) {
    padding: 8px 20px;
    font-size: 15px;
  }
  @media all and (max-width: 360px) {
    font-size: 13px;
  }
`;
const CoverImage = styled.div`
  width: 70px;
  margin: 0 auto 20px;
  img {
    width: 100%;
    display: block;
  }
  @media all and (max-width: 640px) {
    width: 50px;
    margin-bottom: 10px;
  }
  @media all and (max-width: 480px) {
    width: 30px;
  }
`;
const TextLen = styled.p`
  color: #6b6b6b;
  text-align: center;
  margin-bottom: 40px;
  @media all and (max-width: 640px) {
    font-size: 13px;
    margin-bottom: 20px;
  }
  @media all and (max-width: 480px) {
    margin-bottom: 20px;
  }
`;
const Bottom = styled.div`
  display: flex;
  justify-content: center;
`;
