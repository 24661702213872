import React, { useEffect } from "react";
//package
import styled from "styled-components";
import $ from "jquery";
//import
import Maps from "../includes/Maps";

function LocationModal({ isLocationModal, setLocationModal, handleLocationModal, locate, setLocate }) {
    useEffect(() => {
        if (isLocationModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isLocationModal]);
    return (
        <MainContainer>
            {isLocationModal ? (
                <Overlay onClick={() => handleLocationModal()}></Overlay>
            ) : (
                ""
            )}
            <BackContainer style={{ transform: isLocationModal && "scale(1,1)" }}>

                <Modal type="textarea">
                    <div>
                        <Maps locate={locate} setLocate={setLocate} setLocationModal={setLocationModal} handleLocationModal={handleLocationModal} isLocationModal={isLocationModal}/>
                    </div>
                </Modal>
            </BackContainer>
        </MainContainer>
    );
}
export default LocationModal;

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    margin: 0 auto;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
    @media all and (max-width:1380px){
        height:20vh;
        top:311px;
    }
`;

const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
`;
const Modal = styled.div`
    width: 90%;
    max-width: 580px;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    transition: 0.5s;
    z-index: 101;
    border-radius: 5px;
    border: 5px double #f9ad05;
    background: #fff;
    @media all and (max-width:1380px){
        /* top:30%; */
        top: 0;
    }
    @media all and (max-width: 1280px){
        width:500px;
        left:50%;
        /* top:40%; */
    }
    @media all and (max-width:1080px){
        width:485px;
        left:50%;
    }
    @media all and (max-width: 980px) {
        width: 475px;
        /* top:40%; */
    }
    @media all and (max-width: 768px) {
        width: 450px;
        /* top:38%; */
        height:595px;
        left:52%;
    }
    @media all and (max-width: 640px) {
        width: 400px;
        /* top:40%; */
        left:50%;
    }
    @media all and (max-width: 480px) {
        width: 300px;
        height: 510px;
        top:30%;
        left:50%;
    }
    @media all and (max-width: 360px) {
        width: 250px;
        /* height:40px; */
        /* top:60%; */
        left:50%;
    }
`;
const MainContainer = styled.div``;













