import React, { useState } from "react";
import styled from "styled-components";
import Login from "../includes/Login";
import Signup from "../includes/Signup";
import Backgroundslider from "react-background-slider";
import { useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

function Home({
    setLogin,
    scroll,
    setScroll,
    inputValue,
    setInputValue,
    isActive,
    setActive,
}) {
    const [OTP, setOTP] = useState("");

    const location = useLocation();
    return (
        <>
                {/* <Header/> */}
            <Container className="wrapper">
                <Backgroundslider
                    images={[
                        "https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/a.svg",
                        "https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/b.svg",
                        "https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/c.svg",
                    ]}
                    duration={10}
                    transition={2}
                    disableClick={true}
                />
                <Cover>
                    <Left>
                        <LeftContainer>
                            <Logo>
                                <ImageContainer>
                                    <img
                                        src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/homelogo.svg"
                                        alt="Logo img"
                                    />
                                </ImageContainer>
                            </Logo>
                            <Text>Your trust is our responsibility</Text>
                            <Description>
                                Stikcon founded by top-notch technical and
                                business acumen with a track record in AEC
                                (Architectural Engineering and Construction)
                                Industry.
                                <br />
                                <br />
                                Our team includes architects, designers, civil
                                engineers, MEP engineers and data scientists who
                                are joining forces to re-invent modern
                                architecture design, construction project
                                management and we increase the efficiency of
                                construction projects through modern,
                                user-friendly solutions.
                            </Description>
                        </LeftContainer>
                        {location.pathname === "/signup" ? (
                            ""
                        ) : (
                            <Arrow
                                to={"enquiry"}
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={300}
                            >
                                <div className="arrow">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </Arrow>
                        )}
                    </Left>
                    <Right>
                        <LoginCover>
                            {isActive === "Login" ? (
                                <Login
                                    inputValue={inputValue}
                                    setInputValue={setInputValue}
                                    isActive={isActive}
                                    setLogin={setLogin}
                                    setActive={setActive}
                                    OTP={OTP}
                                    setOTP={setOTP}
                                />
                            ) : (
                                <Signup
                                    inputValue={inputValue}
                                    setInputValue={setInputValue}
                                    setActive={setActive}
                                    setLogin={setLogin}
                                    OTP={OTP}
                                    setOTP={setOTP}
                                />
                            )}
                        </LoginCover>
                    </Right>
                </Cover>
            </Container>
        </>
    );
}

export default Home;

const Container = styled.div`
    height: 100vh;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media all and (max-width: 980px) {
        height: unset !important;
    }
    @media all and (max-width: 768px) {
        display: block;
        height: unset;
        align-items: center;
    }
`;
const Left = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 48%;
    margin-bottom: 50px;
    @media all and (max-width: 1366px) {
        margin-bottom: 90px;
    }
    @media all and (max-width: 1280px) {
        margin-bottom: 60px;
    }
    @media all and (max-width: 1080px) {
        width: 60%;
    }
    @media all and (max-width: 768px) {
        width: 100%;
        text-align: center;
        padding-top: 45px;
        justify-content: center;
    }
    @media all and (max-width: 640px) {
        display: block;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
    @media all and (max-width: 360px) {
        padding-top: 70px;
    }
`;
const LeftContainer = styled.div`
    width: 100%;
    @media all and (max-width: 1280px) {
        width: 85%;
    }
    @media all and (max-width: 1080px) {
        width: 90%;
    }
    @media all and (max-width: 980px) {
        padding-top: 60px;
    }
    @media all and (max-width: 768px) {
        align-items: center;
        width: 100%;
    }
    @media all and (max-width: 360px) {
        padding-top: 10px;
    }
`;
const Logo = styled.h1`
    width: 20%;
    display: inline-block;
    margin-bottom: 20px !important;
    @media all and (max-width: 1366px) {
        margin-bottom: 35px;
    }
    @media all and (max-width: 1280px) {
        width: 20%;
        margin-bottom: 30px;
    }
    @media all and (max-width: 980px) {
        width: 25%;
    }
    @media all and (max-width: 768px) {
        width: 20%;
        padding-bottom: 10px;
        margin: 0 auto;
    }
    @media all and (max-width: 480px) {
        margin-bottom: 10px;
        width: 30%;
    }
    @media all and (max-width: 360px) {
        width: 23%;
        margin-bottom: 0!important;
    }
`;
const Arrow = styled(ScrollLink)`
    cursor: pointer;
    @media all and (max-width: 1366px) {
        margin-bottom: -32px;
    }
    @media all and (max-width: 1280px) {
        margin-bottom: -15px;
    }
`;
const ImageContainer = styled.a`
    cursor: pointer;
    & img {
        width: 100%;
        display: block;
    }
    @media all and (max-width: 480px) {
        width: 25%;
    }
`;
const Text = styled.h2`
    color: #f0f0f0;
    margin-bottom: 15px;
    font-size: 26px;
    letter-spacing: 0.6px;
    @media all and (max-width: 1366px) {
        font-size: 24px;
    }
    @media all and (max-width: 980px) {
        font-size: 20px;
        margin-bottom: 10px;
        text-align: left;
    }
    @media all and (max-width: 768px) {
        font-size: 24px;
        margin-bottom: unset;
        text-align: center;
    }
    @media all and (max-width: 640px) {
        font-size: 20px;
    }
    @media all and (max-width: 480px) {
        font-size: 18px;
        margin-bottom: unset;
    }
    @media all and (max-width: 360px) {
        font-size: 18px;
    }
`;
const Description = styled.p`
    color: #eaeaea;
    font-size: 14px;
    opacity: 0.8;
    width: 70%;
    text-align: left;
    padding: 15px 0;
    text-transform: capitalize;
    font-family: 'outfit_light';
    letter-spacing: 0.8px;
    @media all and (max-width: 1366px) {
        width: 90%;
    }
    @media all and (max-width: 1280px) {
        width: 95%;
    }
    @media all and (max-width: 980px) {
        width: 100%;
        text-align: justify;
        margin: 0 auto;
    }
    @media all and (max-width: 768px) {
        width: 90%;
    }
    @media all and (max-width: 640px) {
        width: 100%;
    }
    @media all and (max-width: 480px) {
        font-size: 13px;
        /* width:100%; */
    }
    @media all and (max-width: 360px) {
        padding: 10px 0;
        color: #ffffff;
        opacity: 1;
    }
`;

const LoginCover = styled.div`
    width: 75%;
    padding-top: 30px;
    @media all and (max-width: 1366px) {
        width: 85%;
    }
    @media all and (max-width: 1280px) {
        width: 100%;
    }
    @media all and (max-width: 768px) {
        padding-top: unset;
    }
`;

const Cover = styled.div`
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 980px) {
        display: flex;
        height: 100vh;
        align-items: center;
        justify-content: center;
    }
    @media all and (max-width: 768px) {
        display: block;
        height: unset;
        align-items: center;
    }
`;
const Right = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &.top {
        @media all and (max-width: 980px) {
            display: none;
        }
    }
    &.bottom {
        display: none;
        /* width: 60%; */
        margin: 0 auto;
        @media all and (max-width: 980px) {
            display: block;
        }
        @media all and (max-width: 480px) {
            width: 90%;
        }
    }

    @media all and (max-width: 1080px) {
        width: 60%;
    }
    @media all and (max-width: 980px) {
        margin: 0 auto;
    }
    @media all and (max-width: 768px) {
        width: 90%;
        border: 1px solid #ababab;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
    @media all and (max-width: 360px) {
        margin-bottom: 40px;
    }
`;
