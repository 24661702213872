import React, { useContext, useEffect, useState } from "react";

// packages
import styled from "styled-components";
import validator from "validator";
import arrow from '../../../../assets/images/BUTTON.svg'
import { Link } from "react-router-dom";
import { Context } from "../../../contexts/Store";

function Footer(messageRef) {
  const [emailError, setEmailError] = useState("");
  const date = new Date();
  const {
		state: { user_data },
	} = useContext(Context);
	const isLogin = user_data.isLogin;
  //------Email Validation------------------------

  const validateEmail = (e) => {
    var email = e.target.value;
    if (validator.isEmail(email)) {
      setTimeout(emailError, 3000);
      setEmailError("Valid Email");
    } else {
      setTimeout(emailError, 3000);
      setEmailError("Enter a valid email");
    }
  };
  //-----------------------------------------------
  useEffect(() => {
    setTimeout(() => {
      setEmailError("");
    }, 2200);
  }, [emailError]);

  return (
    <Container id="foot" >
      <div className="wrapper" >
        <Items>
          <Address>
            <LogoLink to={isLogin ? "/tender-management" : "/signup"}
            onClick={()=> document.getElementById("wrapper").scrollTop = 0}
            >
              <LogoCover>
                <img
                  src={
                    "https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/logo.svg"
                  }
                  alt="logo"
                />
              </LogoCover>
            </LogoLink>
            <Description>
              8th Floor, Bizcospaces, Infra Futura Building, Seaport-Airport
              Road, Kakkanad, Kochi
            </Description>
          </Address>
          <Covered>
            {/* <SocialMedia>
              <TextHead>Social Media</TextHead>
              <List>
                <Item href="https://m.facebook.com/stikconpmc/" target="_blank">
                  Facebook
                </Item>
                <Item
                  href="https://www.instagram.com/stikcon/?hl=en"
                  target="_blank"
                >
                  Instagram
                </Item>
                <Item
                  href="https://in.linkedin.com/company/stikcon"
                  target="_blank"
                >
                  Linkedin
                </Item>
                <Item href="https://twitter.com/stikconpmc/" target="_blank">
                  Twitter
                </Item>
              </List>
            </SocialMedia> */}
            <Contact>
              <TextHead>Contact Us</TextHead>
              <List>
                <Cover>
                  <CoverItem>Phone</CoverItem>
                  <CoverItems href="tel:+91 8589998070">
                    +91 8589998070
                  </CoverItems>
                </Cover>
                <Cover type="lastitem">
                  <CoverItem>Email</CoverItem>
                  <CoverItems href="mailto:business@stikcon.com">
                    business@stikcon.com
                  </CoverItems>
                </Cover>
              </List>
            </Contact>
          </Covered>
          <Quote>
            <TextHead>Social Media</TextHead>
            {/* 
            <Description>
              We will reach you as soon as possible, <br />
              Let’s share some thoughts.
            </Description>
            <Form>
              <Input
                type="text"
                placeholder="Enter your email"
                onChange={(e) => validateEmail(e)}
              />
              <Submit>
                <img src={arrow} alt="submit-img" />
              </Submit>
            </Form>
            <MailError>
              {emailError && (
                <HeadShake>
                  <Error
                    style={{
                      color: emailError === "Valid Email" ? "green" : "red",
                    }}
                  >
                    {emailError}{" "}
                  </Error>
                </HeadShake>
              )}
            </MailError> */}
            <Bottom>
              <IconList>
                <Icon
                  href="https://www.instagram.com/stikcon/?hl=en"
                  target="_blank"
                >
                  <img
                    src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/instagram.svg"
                    alt="instagram-img"
                  />
                </Icon>
                <Icon href="https://m.facebook.com/stikconpmc/" target="_blank">
                  <img
                    src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/facebook.svg"
                    alt="facebook-img"
                  />
                </Icon>
                <Icon
                  href="https://in.linkedin.com/company/stikcon"
                  target="_blank"
                >
                  <img
                    src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/linkedin.svg"
                    alt="linkedin-img"
                  />
                </Icon>
                <Icon href="https://twitter.com/stikconpmc/" target="_blank">
                  <img
                    src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/twitter.svg"
                    alt="twitter"
                  />
                </Icon>
                {/* <Icon href="" target="_blank">
                  <img src={youtube} alt="image" />
                </Icon> */}
              </IconList>
            </Bottom>
          </Quote>
        </Items>
        <Content >
          © {date.getFullYear()}, <ContentLink href="https://stikcon.com/" target="blank"> Stikcon </ContentLink>  Private Limited
        </Content>
      </div>
    </Container>
  );
}

export default Footer;

const Container = styled.div`
  background: #00041b;
  color: #fff;
  padding: 40px 0 10px 0;
  opacity: 0.8;
  @media all and (max-width: 640px) {
    padding: 20px 0px;
  }
`;
const Items = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  border-bottom: 1px solid #3f4257;
  padding-bottom: 40px;
  @media all and (max-width: 980px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    padding-bottom: 20px;
  }
  @media all and (max-width: 768px) {
    grid-gap: 15px;
  }
  @media all and (max-width: 640px) {
    grid-template-columns: 2fr;
  }
`;
const Address = styled.div`
  @media all and (max-width: 640px) {
    text-align: center;
    font-size: 22px;
  }
`;
const Covered = styled.div`
  display: flex;
  justify-content: center;
  @media all and (max-width: 640px) {
    text-align: left;
    justify-content: space-evenly;
  }
`;

const LogoLink = styled(Link)``;
const LogoCover = styled.div`
  width: 50%;
  margin-bottom: 5px;
  @media all and (max-width: 1380px) {
    width: 55%;
  }
  @media all and (max-width: 1280px) {
    width: 60%;
    margin-left: 10px;
  }
  @media all and (max-width: 1080px) {
    margin-bottom: unset;
    width: 65%;
  }
  @media all and (max-width: 980px) {
    width: 55%;
    margin-bottom: 10px;
  }
  @media all and (max-width: 768px) {
    width: 50%;
  }
  @media all and (max-width: 640px) {
    margin: 0 auto;
    width: 45%;
  }
  @media all and (max-width: 480px) {
    width: 50%;
  }
  @media all and (max-width: 360px) {
    width: 55%;
  }
`;
const Text = styled.h4`
  margin-bottom: 20px !important;
  text-align: right;
  font-size: 18px;
  @media all and (max-width: 1366px) {
    font-size: 18px;
    font-family: "outfit_medium";
  }
  @media all and (max-width: 1080px) {
    margin-bottom: 7px;
  }
  @media all and (max-width: 980px) {
    text-align: left;
    margin-bottom: 15px !important;
  }
  @media all and (max-width: 640px) {
    text-align: center;
  }
`;
const Description = styled.p`
  color: #fff;
  opacity: 0.6;
  font-size: 15px;
  width: 80%;
  letter-spacing: 0.8px;
  line-height: 130%;
  font-family: 'outfit_light';
  @media all and (max-width: 1366px) {
    font-size: 13px;
  }
  @media all and (max-width: 1080px) {
    margin-bottom: 16px;
  }
  @media all and (max-width: 768px) {
    margin-bottom: 10px;
    font-size: 13px;
  }
  @media all and (max-width: 640px) {
    margin: 0 auto;
    margin-top: 20px;
  }
  @media all and (max-width: 360px) {
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;
const SocialMedia = styled.div`
  @media all and (max-width: 640px) {
    font-size: 22px;
    align-items: left;
  }
`;
const TextHead = styled.h4`
  font-family: "outfit_medium";
  letter-spacing: 0.8px;
  line-height: 130%;
  margin-bottom: 20px !important;
  font-size: 18px;
  @media all and (max-width: 1080px) {
    margin-bottom: 17px;
  }
  @media all and (max-width: 768px) {
    margin-bottom: 7px;
    font-size: 17px;
  }
  @media all and (max-width: 640px) {
    text-align: left;

  }
  @media all and (max-width: 360px) {
    text-align: 15px;
    margin-left: 4px;
  }
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
  @media all and (max-width: 640px) {
    display: flex;
    font-size: 15px;
    width: 80%;
    justify-content: space-between;
    margin: 0 auto;
    text-align: left;
  }
  @media all and (max-width: 360px) {
    width: 90%;
    justify-content: space-between;
    font-size: 10px;
  }
`;
const Item = styled.a`
  opacity: 0.6;
  color: #ffff !important;
  margin-bottom: 15px;
  text-decoration: none;
  font-family: 'outfit_light';
  :last-child {
    margin-bottom: 0;
  }
  @media all and (max-width: 1080px) {
    margin-bottom: 12px;
  }
  @media all and (max-width: 768px) {
    margin-bottom: 10px;
    font-size: 15px;
  }
  @media all and (max-width: 360px) {
    font-size: 14px;
  }
`;
const Contact = styled.div`
  @media all and (max-width: 640px) {
    text-align: center;
    font-size: 22px;
  }
`;
const Cover = styled.div`
  margin-bottom: ${({ type }) => (type === "lastitem" ? "" : "20px")};
  @media all and (max-width: 1080px) {
    margin-bottom: 15px;
  }
`;
const CoverItem = styled.div`
  margin-bottom: 5px;
  font-family: "outfit_regular";
  @media all and (max-width: 1366px) {
    font-size: 13px;
  }
  @media all and (max-width: 768px) {
    font-size: 14px;
  }
`;
const CoverItems = styled.a`
  opacity: 0.6;
  color: #ffff !important;
  font-size: 15px;
  text-decoration: none;
  font-family: 'outfit_light';
`;
const Form = styled.form`
  display: flex;
  align-items: center;

  @media all and (max-width: 768px) {
    font-size: 13px;
  }
`;
const Error = styled.div`
  font-size: 14px;
  font-family: "outfit_light";
  @media all and (max-width: 640px) {
    text-align: left;
  }
`;
const Input = styled.input`
  padding: 11px 15px;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.11);
  opacity: 0.8;
  color: #fff;
  outline: none;
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(123.89deg, #0e94f3 -23.5%, #05e8ba 111.92%);
  border-image-slice: 1;
  @media all and (max-width: 360px) {
    padding: 11px 4px;
  }
`;
const Submit = styled.div`
  background: linear-gradient(123.89deg, #0e94f3 -23.5%, #05e8ba 111.92%);
  cursor: pointer;
  height: 47.5px;
  width: 40px;
  display: flex;
  justify-content: center;
  padding: 0 6px;
  & img {
    display: block;
    width: 65%;
  }
  @media all and (max-width: 1380px) {
    width: 48px;
  }
  @media all and (max-width: 768px) {
    height: 43.5px;
  }
  @media all and (max-width: 480px) {
    width: 40px;
  }
  @media all and (max-width: 360px) {
    height: 43px;
    width: 35px;
  }
`;
const Quote = styled.div`
		display: flex;
		flex-direction: column;
		padding-left: 80px;
	@media all and (max-width: 980px){
		justify-content: left;
	}
	@media all and (max-width: 640px) {
		text-align: center;
		font-size: 23px;
		margin: 0 auto;
    padding-left: 0px;
	}
  @media all and (max-width:320px){
    width: 52%;
  }
`;
const Bottom = styled.div`
  display: inline-block;
  align-items: right;
  @media all and (max-width: 640px) {
    display: block;
    padding: 10px;
  }
  @media all and (max-width:320px){
    padding: 0px 10px 10px 0px;
    margin-left: 5px;
  }
`;
const IconList = styled.div`
	display: flex;
	justify-content: left;
	width: 100%;
	:hover {
		color:#fff !important;
	}
	@media all and (max-width: 980px) {
		width: 95%;
		justify-content:left;
	}
	@media all and (max-width: 640px) {
		margin: 0 auto;

	}
`;
const Icon = styled.a`
  margin-right: 25px;
  height: 18px;
  filter: brightness(0.8);
  :hover {
    filter: brightness(1.5);
  }
  &img {
    display: block;
    width: 100%;
  }
  :hover {
    opacity: 1;
  }
  :last-child {
    margin-right: 0;
  }
  @media all and (max-width: 768px) {
    height: 15px;
    margin-right: 16px;
  }
  @media all and (max-width: 480px) {
    height: 25px;
    margin-right: 25px;
  }
`;
const Content = styled.a`
  opacity: 0.6;
  font-size: 16px;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  color: #ffffff;
  font-family: 'outfit_light';
  letter-spacing: 0.8px;
  line-height: 130%;
  @media all and (max-width: 980px) {
    font-size: 13px;
  }
  @media all and (max-width: 768px) {
  }
  @media all and (max-width: 640px) {
    text-align: center;
    margin-top: 5px;
  }
  :hover {
    color: #ffffff;
  }
`;
const ContentLink = styled.a`
  font-size: 16px;
  display: flex;
  justify-content: center;
  /* padding: 20px 0; */
  color: #ffffff;
  font-family: 'outfit_light';
  letter-spacing: 0.8px;
  line-height: 130%;
  display: inline-block;
  margin: 0px 4px;
  @media all and (max-width: 980px) {
    font-size: 13px;
  }
  @media all and (max-width: 768px) {
  }
  :hover {
    color: #ffffff;
  }
`;
const MailError = styled.div`
  height: 15px;
`;
const HeadShake = styled.div`
	
`;
