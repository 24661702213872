import React, { useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Context } from "../../contexts/Store";
import SectionLoader from "../../ui/general/helpers/SectionLoader";
import AppRouter from "./AppRouter";
import HomePageRouter from "./HomePageRouter";
import WebRouter from "./WebRouter";

function MainRouter() {
	const [scroll, setScroll] = useState(false)
	const [isLoading, setLoading] = useState(true);

	const {
		dispatch,
		state: { user_data },
	} = useContext(Context);
	const isLogin = user_data.isLogin

	useEffect(() => {
		let user_data = localStorage.getItem("user_data");
		user_data = JSON.parse(user_data);
		dispatch({ type: "UPDATE_USER_DATA", user_data: user_data });
		setTimeout(() => setLoading(false), 1100)
	}, []);
	return (
		isLoading ? <SectionLoader /> :
			<Routes>
				<Route path="/web/*" element={<WebRouter />} />
				<Route path='/*' element={
					isLogin ?
						<AppRouter
							setScroll={setScroll}
							scroll={scroll}
						/> : <HomePageRouter />

				}
				/>
			</Routes>
	)
}

export default MainRouter;
