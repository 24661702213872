import { useState,  useEffect } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import Geocode from "react-geocode";
import usePlacesAutocomplete, {
    getGeocode,
} from "use-places-autocomplete";
import "@reach/combobox/styles.css";
import styled from "styled-components";
import SimpleLoader from "../general/helpers/SimpleLoader";
const libraries = ["places"];

export default function Maps({
    setLocate,
    locate,
    isLocationModal,
    // handleLocationModal,
    setLocationModal,
}) {
    const { isLoaded } = useLoadScript({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyA1Big55ZxwdB4Rr63kICLf9WdYN2yCqAc",
        libraries,
    });

    if (!isLoaded)
        return (
            <div>
                <SimpleLoader />{" "}
            </div>
        );
    return (
        <Map
            setLocate={setLocate}
            locate={locate}
            isLocationModal={isLocationModal}
            setLocationModal={setLocationModal}
            // handleLocationModal={handleLocationModal}
        />
    );
}

function Map({
    setLocate,
    value,
    locate,
    isLocationModal,
    // handleLocationModal,
    setLocationModal,
}) {
    const [selected, setSelected] = useState({});
    const [curr, setCurr] = useState("");
    const [prr, setPrr] = useState("");
    const handleLocationModal = ()=>{
        setLocationModal(!isLocationModal)
    }
    //---------------------Convert to Address---------------------------------

    Geocode.setApiKey("AIzaSyA1Big55ZxwdB4Rr63kICLf9WdYN2yCqAc");
    Geocode.setLanguage("en");
    Geocode.setRegion("es");
    Geocode.setLocationType("ROOFTOP");
    Geocode.enableDebug();
    var lat = "";
    var lng = "";

    useEffect(() => {
        Geocode.fromLatLng(curr, prr).then(
            (response) => {
                
                let city, state, country;
                for (
                    let i = 0;
                    i < response.results[0].address_components.length;
                    i++
                ) {
                    for (
                        let j = 0;
                        j <
                        response.results[0].address_components[i].types.length;
                        j++
                    ) {
                        // eslint-disable-next-line default-case
                        switch (
                            response.results[0].address_components[i].types[j]
                        ) {
                            case "locality":
                                city =
                                    response.results[0].address_components[i]
                                        .long_name;
                                break;
                            case "administrative_area_level_1":
                                state =
                                    response.results[0].address_components[i]
                                        .long_name;
                                break;
                            case "country":
                                country =
                                    response.results[0].address_components[i]
                                        .long_name;
                                break;
                        }
                    }
                }
                const loc = city + ", " + state + ", " + country;
                setLocate(loc);
            },
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    //==================getLocation=================================

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    };

    useEffect(() => {
        setLocate(getLocation);
        getAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [curr]);

    function showPosition(position) {
        lat = position.coords.latitude;
        lng = position.coords.longitude;
        setCurr(lat);
        setPrr(lng);
        setSelected(new window.google.maps.LatLng(lat, lng));
    }

    //==========================================================
    function getAddress(value) {
        const latlng = value;
        var geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ latLng: latlng }, function (results, status) {
            if (status === window.google.maps.GeocoderStatus.OK) {
                let city, state, country;
                for (let i = 0; i < results[0].address_components.length; i++) {
                    for (
                        let j = 0;
                        j < results[0].address_components[i].types.length;
                        j++
                    ) {
                        switch (results[0].address_components[i].types[j]) {
                            case "locality":
                                city =
                                    results[0].address_components[i].long_name;
                                break;
                            case "administrative_area_level_1":
                                state =
                                    results[0].address_components[i].long_name;
                                break;
                            case "country":
                                country =
                                    results[0].address_components[i].long_name;
                                break;
                        }
                    }
                }
                const pickedLocation = city + ", " + state + ", " + country;
                setLocate(pickedLocation);
                handleLocationModal();
            }
        });
    }

    return (
        <MapContainer id="mapContainer">
            <CloseButton onClick={() => handleLocationModal()}>
                <img
                    src="https://steyp.com/static/media/close-button.4446380e.svg"
                    alt="close button"
                />
            </CloseButton>
            <div className="places-container">
                <PlacesAutocomplete
                    setSelected={setSelected}
                    setLocate={setLocate}
                    setLocationModal={setLocationModal}
                    handleLocationModal={handleLocationModal}
                    locate={locate}
                />
            </div>
            <GoogleMap
                zoom={10}
                center={selected}
                mapContainerClassName="map-container"
                onClick={(event) => {
                    getAddress(event.latLng);
                    setSelected(event.latLng);
                }}
            >
                <Marker position={selected} />
            </GoogleMap>
        </MapContainer>
    );
}
//---------------------------placeAutocomplete--------------------------------------------

const PlacesAutocomplete = ({
    setSelected,
    setLocate,
    locate,
    handleLocationModal,
    setLocationModal,
}) => {
    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
    } = usePlacesAutocomplete();
    const handleSelect =
        ({ description }) =>
        () => {
            setValue(description, false);
            clearSuggestions();

            getGeocode({ address: description }).then((results) => {
                const loc = results[0].formatted_address;
                setLocate(loc);
                setLocationModal(false);
            });
        };

    const renderSuggestions = () =>
        data.map((suggestion) => {
            const {
                place_id,
                structured_formatting: { main_text, secondary_text },
            } = suggestion;
            return (
                <li
                    className="input-option"
                    key={place_id}
                    onClick={handleSelect(suggestion)}
                >
                    <strong>{main_text}</strong> <small>{secondary_text}</small>
                </li>
            );
        });
    const handleInput = (e) => {
        setValue(e.target.value);
    };



    return (
        <Container>
            <input
                className="combobox-input"
                value={value}
                type="search"
                onChange={handleInput}
                disabled={!ready}
                placeholder="Please select your location"
            />

            {status === "OK" && (
                <ul className="input-drop">{renderSuggestions()}</ul>
            )}
        </Container>
    );
};

const Container = styled.div`
    input {
        ::-webkit-search-cancel-button {
            cursor: pointer;
        }
    }
`;
const MapContainer = styled.div`
    position: relative;
`;
const CloseButton = styled.div`
    position: absolute;
    width: 45px;
    cursor: pointer;
    right: -35px;
    z-index: 100;
    top: 0;
    img {
        display: block;
        width: 100%;
    }
`;
