import React, { useContext, useEffect, useRef, useState } from "react";
//package
import styled from "styled-components";
import OtpInput from "react-otp-input";
import $ from "jquery";
import { accountsConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import LoginSuccessModal from "./LoginSuccessModal";

function OtpModal({
    isModal,
    handleModal,
    setModal,
    setShow,
    locate,
    inputValue,
    userInputValue,
    email,
    phonenumber,
    OTP,
    setOTP,
    showOtp,
    setShowOtp,
}) {
  const [counter, setCounter] = useState(30);
  const [isError, setIsError] = useState();
  const [error, setError] = useState(false);
  const [send, setSend] = useState(false);
  const [searchParams] = useSearchParams();
  const nextPath = searchParams.get("next");
  const [success, setSuccess] = useState(false);
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const handleChange = (otp) => {
    setOTP(otp);
  };
  //recaptcha
  const recaptchaRef = useRef(null);

  const navigate = useNavigate();
  useEffect(() => {
    if (isModal) {
      $("html").addClass("modal-enabled");
    } else {
      $("html").removeClass("modal-enabled");
    }
  }, [isModal]);

  //----------------------------------------------------------------
  useEffect(() => {
    const counterClock = () => {
      if (counter > 0) {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
      }
    };
    isModal && counterClock();
  }, [counter, isModal]);

    //----------------------------------------------------------------
    useEffect(() => {
        const counterClock = () => {
            if (counter > 0) {
                counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
            }
        };
        isModal && counterClock();
    }, [counter, isModal]);

    //----------------------------------------------------------------
    useEffect(() => {
        setTimeout(() => {
            setSend(false);
        }, 5500);
    }, [send]);
    //--------------------otp verification----------------------------
    const handleOtpVerification = async (e) => {
        const token = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();
        accountsConfig
            .post("api/v1/users/signup/verify-otp/", {
                email: user_data.email,
                country_web_code: user_data.country_web_code,
                phone: user_data.phone,
                service_name: "supports",
                otp: OTP,
                "g-recaptcha-response": token,
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setModal(false);
                    setShow(true);
                    setSuccess(true);
                    dispatch({
                        type: "UPDATE_USER_DATA",
                        user_data: {
                            email: user_data.email,
                            country_web_code: "IN",
                            phone: inputValue,
                            isLogin: true,
                            access_token: data.response.access_token,
                            user_id: data.username,
                        },
                    });
                    nextPath ? navigate(nextPath) : navigate("/");
                } else {
                    // setLoading(false);
                    setError(data.message);
                    // setErrorMsg(data.message);
                }
            })
            .catch((error) => {
                console.log(error);
                setError(true);
            });
    };
    //----------------------------------------------------------------
    //-------------------otp phone/email(Login) verification-----------------

    const handleOtpVerify = (e) => {
        e.preventDefault();
        if (isNaN(userInputValue)) {
            accountsConfig
                .post("api/v1/users/verify-otp/email/", {
                    email: user_data.email,
                    service_name: "supports",
                    otp: OTP,
                })
                .then((response) => {
                    const { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        setModal(false);
                        setShow(true);
                        navigate("/");
                        dispatch({
                            type: "UPDATE_USER_DATA",
                            user_data: {
                                email: user_data.email,
                                isLogin: true,
                                user_id: data.username,
                                access_token: data.response.access_token,
                            },
                        });
                    } else {
                        // setLoading(false);
                        setError(data.message);
                        setOTP("");
                        // setErrorMsg(data.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setError(true);
                });
        } else {
            accountsConfig
                .post("api/v1/users/verify-otp/phone/", {
                    country_web_code: user_data.country_web_code,
                    phone: user_data.phone,
                    service_name: "supports",
                    otp: OTP,
                })
                .then((response) => {
                    const { StatusCode, data } = response.data;
                    console.log(data,"data from verify-otp phone")
                    if (StatusCode === 6000) {
                        setModal(false);
                        setShow(true);
                        dispatch({
                            type: "UPDATE_USER_DATA",
                            user_data: {
                                phone: user_data.phone,
                                isLogin: true,
                                user_id: data.username,
                                access_token: data.response.access_token,
                            },
                        });
                        nextPath ? navigate(nextPath) : navigate("/");
                    } else {
                        // setLoading(false);
                        setOTP("");
                        setError(data.message);
                        // setErrorMsg(data.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setError(true);
                });
        }
    };
    //----------------------Resend otp--------------------------------
    const resendOtp = async (e) => {
        setOTP("");
        e.preventDefault();
        const token = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();

        if (isNaN(userInputValue)) {
            accountsConfig
                .post("api/v1/users/resend-otp/email/", {
                    email: user_data.email,
                    "g-recaptcha-response": token,
                })
                .then((response) => {
                    const { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        dispatch({
                            type: "UPDATE_USER_DATA",
                            user_data: {
                                email: user_data.email,
                            },
                        });
                    } else {
                        // setLoading(false);
                        setOTP("");
                        setError(data.message);
                        // setErrorMsg(data.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setError(true);
                });
        } else {
            accountsConfig
                .post("api/v1/users/resend-otp/phone/", {
                    country_web_code: user_data.country_web_code,
                    phone: user_data.phone,
                    "g-recaptcha-response": token,
                })
                .then((response) => {
                    const { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        // setModal(false);
                        // setShow(true)
                        setShowOtp(data.otp);
                        dispatch({
                            type: "UPDATE_USER_DATA",
                            user_data: {
                                phone: user_data.phone,
                            },
                        });
                    } else {
                        // setLoading(false);
                        setOTP("");
                        setError(data.message);
                        // setErrorMsg(data.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setError(true);
                });
        }
    };
    useEffect(() => {
        error === "Please wait 15 minutes to try again"
            ? setTimeout(() => {
                  setError(false);
              }, 900000)
            : setTimeout(() => {
                  setError(false);
              }, 3200);
        if (error === "Invalid OTP") {
            setIsError(true);
        } else {
            setIsError(false);
        }
    }, [error]);
    setTimeout(() => {
      setSend(false);
    }, 5500);
    const handleKeyPress = (e) => {
        document.onkeyup = enter;
        function enter(e) {
            if (e.which === 13) locate && handleOtpVerification(e);
            userInputValue && handleOtpVerify(e);
        }
    };
    //-------------------------resend in signup---------------------------------------
    const resendOtpSignup = async (e) => {
        e.preventDefault();
        const token = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();
        accountsConfig
            .post("api/v1/users/resend-otp/phone/", {
                country_web_code: user_data.country_web_code,
                phone: user_data.phone,
                "g-recaptcha-response": token,
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    // setModal(false);
                    // setShow(true)
                    dispatch({
                        type: "UPDATE_USER_DATA",
                        user_data: {
                            phone: user_data.phone,
                        },
                    });
                } else {
                    // setLoading(false);
                    setError(data.message);
                    // setErrorMsg(data.message);
                }
            })
            .catch((error) => {
                console.log(error);
                setError(true);
            });
        accountsConfig
            .post("api/v1/users/resend-otp/email/", {
                email: user_data.email,
                "g-recaptcha-response": token,
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    dispatch({
                        type: "UPDATE_USER_DATA",
                        user_data: {
                            email: user_data.email,
                        },
                    });
                } else {
                    // setLoading(false);
                    setError(data.message);
                    // setErrorMsg(data.message);
                }
            })
            .catch((error) => {
                console.log(error);
                setError(true);
            });
    };
    //----------------------------------------------------------------
    return (
        <>
        <MainContainer>
            {isModal ? <Overlay></Overlay> : ""}
            <BackContainer style={{ transform: isModal && "scale(1,1)" }}>
                <Modal type="textarea">
                    <Container>
                        <Left>
                            <Text>Enter Verification Code</Text>
                            <SubText>
                                <span>
                                    We have send you a verification code to your
                                </span>
                                <div>
                                    <Span>
                                        {isNaN(inputValue) ? " " : "+91 "}
                                        {phonenumber ? " +91 " : ""}
                                    </Span>
                                    <Span>
                                        {inputValue}{" "}
                                        
                                        {phonenumber
                                            ? phonenumber || email
                                            : ""}{" "}
                                            <br/>
                                        {/* <span>
                                            {phonenumber && email && "and"}
                                        </span> */}{" "}
                                        {email}{" "}
                                    </Span>
                                </div>
                                {/* ---------------------Comment this when updating live-------------------- */}

                                {/* <div>Your OTP is : {showOtp}</div> */}
                                {/* --------------------------------------------------------------------------- */}
                            </SubText>
                            <Otp>
                                <OtpInput
                                    value={OTP}
                                    onChange={(value) => {
                                        handleChange(value);
                                    }}
                                    numInputs={4}
                                    placeholder={"0000"}
                                    inputStyle={isError ? "error" : "OtpInput"}
                                    containerStyle={
                                        isError ? "errorstyle" : "OtpStyle"
                                    }
                                    isInputNum={true}
                                    onKeyPress={(e) => handleKeyPress(e)}
                                />
                                <CoverOtp type={error}>
                                    {error ? (
                                        <ErrorMessege>
                                            {error ===
                                            "otp - A valid integer is required."
                                                ? "Enter a Valid OTP"
                                                : error}
                                        </ErrorMessege>
                                    ) : (
                                        ""
                                    )}
                                    {!error ? (
                                        <Resend
                                            onClick={(e) => {
                                                counter === 0 &&
                                                    inputValue &&
                                                    resendOtp(e);
                                                locate && resendOtpSignup(e);
                                                counter === 0 && setSend(true);
                                            }}
                                            type={send ? "send" : counter}
                                        >
                                            {send
                                                ? "OTP send"
                                                : counter === 0
                                                ? "Resend OTP"
                                                : "Resend code? "}
                                            {send ? (
                                                ""
                                            ) : (
                                                <span type={counter}>
                                                    {counter} sec
                                                </span>
                                            )}
                                        </Resend>
                                    ) : (
                                        ""
                                    )}
                                </CoverOtp>
                            </Otp>

                            <ReCAPTCHA
                                ref={recaptchaRef}
                                //This ref can be used to call captcha related functions in case you need.
                                sitekey="6LeTewMiAAAAAOJJMNKPlq3oG07U1XylrqchzXnJ"
                                size="invisible"
                                badge="bottomleft"
                            />
                            <Button
                                to="/"
                                onClick={(e) => {
                                    locate && handleOtpVerification(e);
                                    userInputValue && handleOtpVerify(e);
                                }}
                            >
                                <span>Verify</span>
                                {/* <ReCAPTCHA
                                    ref={recaptchaRef}
                                    //This ref can be used to call captcha related functions in case you need.
                                    sitekey="6LeTewMiAAAAAOJJMNKPlq3oG07U1XylrqchzXnJ"
                                    size="invisible"
                                    badge="bottomleft"
                                /> */}
                            </Button>
                        </Left>
                        <Right>
                            <ImageContainer>
                                <img
                                    src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/shield.svg"
                                    alt="Lock icon"
                                />
                            </ImageContainer>
                        </Right>
                        <CloseIcon onClick={() => handleModal()}>
                            <img
                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/close.svg"
                                alt="Close icon"
                            />
                        </CloseIcon>
                    </Container>
                </Modal>
            </BackContainer>
        </MainContainer>
        <LoginSuccessModal
            success={success}
            setSuccess={setSuccess}
        />
        </>
    );
}

export default OtpModal;

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 90%;
    height: 100vh;
    max-width: 650px;
    z-index: 1000;
    right: 0;
    margin: 0 auto;
    left: 0;
    top: 0px;
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 650px;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    transition: 0.5s;
    z-index: 101;
    border-radius: 5px;
    background: #fff;
    @media all and (max-width: 1280px) {
        width: 520px;
    }
    @media all and (max-width: 1080px) {
        width: 500px;
    }
    @media all and (max-width: 980px) {
        width: 600px;
    }
    @media all and (max-width: 768px) {
        width: 480px;
    }
    @media all and (max-width: 640px) {
        width: 450px;
    }
    @media all and (max-width: 480px) {
        width: 340px;
    }
    @media all and (max-width: 360px) {
        width: 300px;
    }
`;
const MainContainer = styled.div``;
const ErrorMessege = styled.div`
    width: 100%;
    color: #e46b6b;
    margin-top: 5px;
    font-size: 14px;
`;
const CoverOtp = styled.div`
    display: flex;
    justify-content: ${({ type }) => (type ? "flex-start" : "flex-end")};
    align-items: center;
    width: ${({ type }) => (!type ? "90%" : "")};
`;
const Otp = styled.div`
    width: 100%;
    height: 50px;
    &.OtpInput {
        background-color: red;
        color: red;
    }
    @media all and (max-width: 1280px) {
        height: 45px;
    }
`;
const Container = styled.div`
    padding: 50px 0px 50px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    @media all and (max-width: 1080px) {
        padding: 50px 0px 40px 40px;
    }
    @media all and (max-width: 640px) {
        padding: 50px 0px 30px 40px;
    }
    @media all and (max-width: 360px) {
        padding: 50px 0px 30px 30px;
    }
`;
const Left = styled.div`
    width: 100%;
    /* margin-right: 20px; */
    @media all and (max-width: 1280px) {
        width: 65%;
    }
    @media all and (max-width: 480px) {
        width: 85%;
    }
`;
const SubText = styled.div`
    color: #5a5a5a;
    margin-bottom: 40px;
    font-size: 16px;
    width: 100%;
    span {
        font-family: 'outfit_light';
        
    }
    div {
        @media all and (max-width: 320px){
            display: inline;
            margin-left: 5px;
        }
    }
    @media all and (max-width: 1280px) {
        margin-bottom: 35px;
        font-size: 15px;
        width: 90%;
    }
    @media all and (max-width: 1080px) {
        width: 95%;
    }
    @media all and (max-width: 768px) {
        font-size: 14px;
    }
    @media all and (max-width: 640px) {
        font-size: 13px;
        width: 100%;
    }
    @media all and (max-width: 480px) {
        margin-bottom: 30px;
        font-size: 14px;
    }
    @media all and (max-width: 360px) {
        font-size: 13px;
    }
`;
const Resend = styled.div`
    color: ${({ type }) =>
        type === 0 ? "#0B7ADD" : type === "send" ? "#39C78B" : "#5A5A5A"};
    font-size: 14px;
    margin-top: 5px;
    letter-spacing: 0.8px;
    text-align: ${({ type }) =>
        type === "send" ? "right" : type === 0 ? "right" : ""};
    cursor: ${({ type }) => (type === 0 ? "pointer" : "")};
    /* /* width: 50%; */
    width: 100%;
    /* margin-left: 57%; */
    text-align: right;
    span {
        display: ${({ type }) => (type === 0 ? "none" : "inline-block")};
        width: 45px;
        color: #0b7add;
    }
    @media all and (max-width: 1280px) {
        font-size: 13px;
        margin-top: 10px;
    }
    @media all and (max-width: 640px) {
        width: 88%;
    }
    @media all and (max-width: 480px) {
        text-align: center;
    }
`;
const Button = styled(Link)`
    margin-top: 80px;
    cursor: pointer;
    border-radius: 8px;
    background: linear-gradient(255.47deg, #0881e0 0%, #05e8ba 102.09%);
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
        color: #fff;
        letter-spacing: 0.8px;
    }
    @media all and (max-width: 640px) {
        margin-top: 65px;
    }
`;
const Right = styled.div`
    right: 40%;
`;
const CloseIcon = styled.div`
    width: 20px;
    height: 20px;
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    img {
        width: 100%;
        display: block;
    }
    @media all and (max-width: 480px) {
        top: 25px;
        right: 25px;
    }
`;
const ImageContainer = styled.div`
    width: 80%;
    margin: 0 auto;
    img {
        width: 100%;
        display: block;
    }
    @media all and (max-width: 640px) {
        width: 90%;
    }
    @media all and (max-width: 480px) {
        display: none;
    }
`;
const Text = styled.div`
    color: #0b7add;
    margin-bottom: 10px;
    font-size: 28px;
    font-family: "outfit_medium";
    @media all and (max-width: 1280px) {
        font-size: 25px;
    }
    @media all and (max-width: 640px) {
        font-size: 22px;
    }
    @media all and (max-width: 480px) {
        font-size: 24px;
    }
    @media all and (max-width: 360px) {
        font-size: 21px;
    }
`;
const Span = styled.span`
    color: #404A7C;
    font-family: "outfit_medium";
    span {
        color: #5a5a5a;
    }
`;
