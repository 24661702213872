import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";

// packages
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import Select from "react-select";
import Bounce from "react-reveal/Bounce";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";

// config & store
import { supportsConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import SuccessModal from "../modal/SuccessModal";
import ButtonLoader from "../general/helpers/ButtonLoader";

function AddEnquiry() {
  const [files, setFiles] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [description, setDescription] = useState("");
  const [subject, setSubject] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [isCaptchaValid, setCaptchaValid] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isShow, setShow] = useState(false);
  const [modalType, setModalType] = useState("");
  const [scrollto, setScrollto] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [optionsValue, setOptionsValue] = useState([]);
  const [categoryUsername, setCategoryUsername] = useState();
  const [isError, setIsError] = useState("");

  const {
    dispatch
  } = useContext(Context);

  const handleSuccessModal = () => {
    setShow(!isShow);
    setLoading(false);
  };

  useEffect(() => {
    loadCaptchaEnginge(6, "#042941", "white");
  }, []);

  const categoryvalidation = () => {
    categoryUsername?.id.length === undefined
      ? setIsError("Please select a category")
      : setIsError("");
  };
  const submitHandler = () => {
    subject.length > 0 &&
      description.length > 0 &&
      categoryUsername?.id &&
      setShow(true);
    subject.length === 0 &&
      description.length === 0 &&
      !categoryUsername?.id &&
      setLoading(false);
  };
  const doSubmit = (e) => {
    let user_captcha = document.getElementById("user_captcha_input").value;
    if (validateCaptcha(user_captcha) === true) {
      // alert("Captcha Matched");
      setCaptchaValid(true);
      uploadHandler(e);
      submitHandler(e);
      document.getElementById("user_captcha_input").value = "";
    } else if (!user_captcha) {
      setErrorMsg("Enter a valid captcha");
      setCaptchaValid(false);
    } else {
      // alert("Captcha Does Not Match");
      setErrorMsg("Invalid captcha");
      setCaptchaValid(false);
      document.getElementById("user_captcha_input").value = "";
    }
  };
  //-----------------------------------------------------------------------------
  const setClear = ()=>{
    setSubject("");
    setDescription("");
    setFiles("");
    setCaptcha(null);
    setCategoryUsername("");
  }
  const removeFile = (filename) => {
    setFiles(files.filter((file) => file.name !== filename));
  };
  const uploadFileHandler = (event) => {
    if (event.target.files[0] === undefined) {
      return null;
    } else {
      setFiles([...files, event.target.files[0]]);
    }
  };

  const uploadHandler = (event) => {
    setLoading(true);
    event.preventDefault();

    const formData = new FormData();
    formData.append("category_pk", categoryUsername?.id);
    formData.append("title", subject);
    formData.append("description", description);

    for (let i = 0; i < files.length; i++) {
      formData.append(`attachments`, files[i]);
    }

    dispatch({
      type: "UPDATE_ENQUIRY_DATA",
      enquiry_datas: {
        formdata: formData,
        fromAddEnquiry: true,
      },
    });
  };
  //----------------------------------------------------------

  useEffect(() => {
    supportsConfig
      .get("api/v1/enquiries/enquiry-category/list/", {
        params: {
          filter_status: "True",
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data;
        if (StatusCode === 6000) {
          setCategoryList(data.data);
          // setIsError("");
        } else {
          setIsError();
        }
      })
      .catch((error) => {});
  }, []);
  useEffect(() => {
    if (optionsValue.length === 0) {
      fetchoptions();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryList]);

  const fetchoptions = () => {
    categoryList?.map((item) => {
      setOptionsValue((prev) => [
        ...prev,
        { value: item.title, label: item.title, id: item.id },
      ]);
    });
  };

  const options = optionsValue;

  return (
    <Container id="enquiry">
      <Wrapper className="wrapper">
        <Fade top>
          <Topbar>
            <SubText>ENQUIRY</SubText>
            <Text>
              Add <span>Enquiry</span>
            </Text>
            <Description>
              We are here to clarify any of your construction-related doubts and
              enquiries. Drop your queries below!
            </Description>
          </Topbar>
        </Fade>
        <Bounce bottom>
          <Frame>
            <EnquiryCard>
              <Row>
                <Cover>
                  <Label>Category</Label>
                  <Select
                    isSearchable={false}
                    defaultValue={{ label: "Select", value: "Select" }}
                    className="select-option"
                    options={options}
                    onChange={setCategoryUsername}
                  />
                  {!categoryUsername && errorMsg && (
                    <ErrorMsgCat>
                      <span>This field is required </span>
                    </ErrorMsgCat>
                  )}
                </Cover>
                <Cover>
                  <Labels>Subject</Labels>
                  <TextField
                    type="text"
                    placeholder="Enter subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                  {!subject && errorMsg && (
                    <ErrorMsg>
                      <span>This field is required </span>
                    </ErrorMsg>
                  )}
                </Cover>
              </Row>
              <Cover>
                <Label>Description</Label>
                <TextArea
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  placeholder="Enter description"
                  // type="text"
                />
                {!description && errorMsg && (
                  <ErrorMessages>
                    <span>This field is required </span>
                  </ErrorMessages>
                )}
              </Cover>
              <Cover>
                <Label>Attachments</Label>
                <Attachment>
                  <CoverScroll>
                    <Items>
                      {files &&
                        files.map((f, index) => (
                          <Item key={f.name}>
                            <ImgContainer
                            className={files.length >= 2 ? "shrink" : ""}
                            >
                              <img
                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/fileupload.svg"
                                alt="Attachments"
                              />
                            </ImgContainer>
                            <TextName>{f.name}</TextName>
                            <ImgContainer
                              onClick={() => {
                                // deleteFileHandler(f.name);
                                // setRemoveFile(f.id)
                                removeFile(f.name);
                              }}
                            >
                              <img
                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/closeicon.svg"
                                alt="Close"
                              />
                            </ImgContainer>
                          </Item>
                        ))}
                    </Items>
                  </CoverScroll>
                  <ButtonContainer>
                    <Input
                      type="file"
                      placeholder="Attachments"
                      onChange={uploadFileHandler}
                      // multiple
                    />
                    <Icon>
                      <img
                        src={require("../../../assets/images/AttachmentIcon.svg").default}
                        alt="attachment"
                      />
                    </Icon>
                  </ButtonContainer>
                </Attachment>
              </Cover>
              <Div>
                <Left>
                  <LoadCanvasTemplateNoReload />
                </Left>
                <Right>
                  <input
                    placeholder="Enter the text"
                    id="user_captcha_input"
                    name="user_captcha_input"
                    type="text"
                    onChange={(e) => setCaptcha(e.target.value)}
                  />
                </Right>
                <ErrorMessage>
                  {!isCaptchaValid && errorMsg && (
                    <>
                      <span>{errorMsg}</span>
                    </>
                  )}
                </ErrorMessage>
              </Div>
              <CoverSubmit>
                <Cancel onClick={()=>{
                  setClear();
                }}>
                  Cancel
                </Cancel>
                <Submit
                  id="reload"
                  // to={subject && isCaptchaValid  && "/"}
                  onClick={(e) => {
                    // uploadHandler(e);
                    // {categoryUsername && description && subject &&
                    doSubmit(e);
                    // };
                    // setLoading(false)
                    setModalType("form");
                    categoryvalidation();
                  }}
                >
                  {isLoading ? <ButtonLoader /> : "Submit"}
                </Submit>
              </CoverSubmit>
            </EnquiryCard>
          </Frame>
        </Bounce>
        <SuccessModal
          handleSuccessModal={handleSuccessModal}
          modalType={modalType}
          isShow={isShow}
          setScrollto={setScrollto}
          scrollto={scrollto}
        />
      </Wrapper>
    </Container>
  );
}

export default AddEnquiry;

const ErrorMessage = styled.div`
  position: absolute;
  width: 100%;
  color: red;
  height: 10px;
  font-size: 13px;
  bottom: -15px;
  left: 0;
`;
const Container = styled.div`
  background: url("https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/backgroundadd.png");
  padding: 70px 0;
  @media all and (max-width: 1280px) {
    padding: 50px 0;
  }
  @media all and (max-width: 480px) {
    padding: 40px 0;
  }
`;
const Items = styled.div`
  max-width: 500px;
  display: flex;
  align-items: center;
`;
const Item = styled.div`
  display: flex;
  /* overflow: hidden; */
  margin-right: 5px;
  justify-content: space-between;
  align-items: center;
  padding: 6px 10px;
  width: 160px;
  background: #00395d;
  border: 1px solid #0884df;
  border-radius: 2px;
  @media all and (max-width: 1366px) {
    padding: 1px 10px;
    margin-left: 6px;
  }
`;
const TextName = styled.div`
  color: #707070;
  font-family: "outfit_light";
  font-size: 14px;
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ImgContainer = styled.div`
  margin-right: 5px;
  width: 9%;
  @media all and (max-width:360px){
    width: 10%;
    &.shrink{
    width: 15%;
  }
  }
  :last-child {
    margin-left: 5px;
    margin-right: 0;
    cursor: pointer;
  }
  img {
    width: 100%;
    display: block;
  }
 
`;
const Wrapper = styled.div`
  width: 45%;
  margin: 0 auto;
  @media all and (max-width: 1366px) {
    width: 50%;
  }
  @media (max-width: 1080px) {
    width: 60%;
  }
  @media (max-width: 980px) {
    width: 75%;
  }
  @media (max-width: 640px) {
    width: 90%;
  }
  @media (max-width: 320px) {
    width: 90%;
  }
`;
const Topbar = styled.div`
  text-align: center;
`;
const Description = styled.p`
  color: #ffff;
  padding-bottom: 20px;
  font-family: "outfit_light";
  width: 70%;
  margin: 0 auto;
  margin-bottom: 10px;
  letter-spacing: 1px;
  line-height: 25px;
  @media all and (max-width: 1280px) {
    width: 75%;
  }
  @media all and (max-width: 640px) {
    font-size: 14px;
    width: 90%;
  }
  @media all and (max-width: 480px) {
    text-align: center;
    width: 100%;
  }
`;
const Input = styled.input`
  position: absolute;
  right: 7px;
  width: 37px;
  height: 37px;
  opacity: 0;
  cursor: pointer;
  outline-color: #0884df !important;
`;
const Div = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  justify-content: space-between;
  position: relative;
  @media all and (max-width: 1366px) {
    margin-top: 33px;
  }
  @media (max-width: 640px) {
    flex-direction: column;
  }
`;
const Left = styled.div`
  border: 1px solid #022334;
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 20px 0 20px;
  margin-right: 20px;
  width: 100%;
  background-color: #042941 !important;
  border-radius: 3px;
  height: 50px;

  @media all and (max-width: 1380px) {
    height: 50px;
    width: 51%;
    margin-right: 24px;
  }
  @media (max-width: 640px) {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
  }
  @media all and (max-width: 480px) {
    margin-bottom: 30px;
  }
`;
const Right = styled.div`
  background: #042941;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #eeeeee;
  input:focus {
    outline: none !important;
  }
  &:hover {
    border: 1px solid #0883e0;
  }
  input {
    color: #fff;
    width: 100%;
    font-size: 17px;
    outline: none !important;
    padding: 14px;
    font-family: "outfit_light";
    line-height: 25px;
    letter-spacing: 1px;
    @media all and (max-width: 980px) {
    font-size: 16px;
  }
  }
  @media (max-width: 640px) {
    width: 100%;
  }
`;
const EnquiryCard = styled.div`
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0px 4px 34px rgba(20, 61, 81, 0.1);
  backdrop-filter: blur(3px);
  padding: 40px;
  border-radius: 2px;
  @media all and (max-width: 1366px) {
    padding: 30px 35px;
  }
  @media (max-width: 640px) {
    padding: 12px;
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 640px) {
    flex-direction: column;
  }
`;
const CoverScroll = styled.div`
  overflow-x: scroll;
  margin-right: 10px;
  ::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-track {
    border-radius: 0;
    background: #eeeeee;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: #b0b0b0;
  }
`;
const ButtonContainer = styled.div`
  width: 6%;
  @media all and (max-width: 1370px) {
    margin-right: 10px;
  }
  @media all and (max-width: 980px) {
    width: 6%;
  }
  @media all and (max-width: 768px) {
    width: 7%;
  }
  @media all and (max-width: 640px) {
    width: 8%;
  }
  @media all and (max-width: 480px) {
    width: 10%;
  }
  @media all and (max-width: 360px) {
    width: 13%;
  }
`;
const SubText = styled.h5`
  position: relative;
  color: #cecece;
  font-size: 13px;
  width: 86%;
  font-family: "outfit_medium";
  margin-bottom: 10px !important;
  ::after {
    content: "";
    width: 80px;
    height: 1px;
    background: #fff;
    position: absolute;
    bottom: 4px;
    margin-left: 5px;
  }
  @media all and (max-width: 480px) {
    text-align: center;
    width: 95%;
    ::after {
      width: 55px;
      height: 1px;
    }
  }
`;
const Text = styled.h3`
  color: #fff;
  font-family: "outfit_medium";
  font-size: 34px;
  margin-bottom: 40px !important;
  padding-left: 19px;
  span {
    color: #07bccb;
  }
  @media all and (max-width: 1366px) {
    margin-bottom: 15px;
  }
  @media all and (max-width: 768px) {
    font-size: 30px;
    margin-bottom: 20px;
  }
  @media all and (max-width: 640px) {
    font-size: 26px;
  }
  @media all and (max-width: 480px) {
    text-align: center;
  }
`;
const ErrorMsg = styled.div`
  position: absolute;
  bottom: -22px;
  span {
    color: red;
    font-size: 13px;
  }
  sup {
    color: red;
  }
  @media all and (max-width: 1380px) {
    /* left:20px; */
    bottom: -20px;
  }
`;
const ErrorMsgCat = styled.div`
  position: absolute;
  bottom: -16px;
  span {
    color: red;
    font-size: 13px;
  }
  sup {
    color: red;
  }
  @media all and (max-width: 1380px) {
    /* left:20px; */
    bottom: -14px;
  }
`;
const ErrorMessages = styled.div`
  position: absolute;
  bottom: -21px;
  span {
    color: red;
    font-size: 13px;
  }
  sup {
    color: red;
  }
`;
const Cover = styled.div`
  margin-bottom: 30px;
  width: 100%;
  position: relative;
`;
const Label = styled.h5`
  color: #dcdcdc;
  font-size: 15px;
  font-family: "outfit_light";
  margin-bottom: 5px !important;
  display: inline-block;
`;
const Labels = styled.h5`
  color: #dcdcdc;
  font-size: 15px;
  margin-bottom: 3px !important;
  font-family: "outfit_light";
  @media all and (max-width: 768px) {
    margin-left: 20px;
  }
  @media all and (max-width: 640px) {
    margin-left: unset;
  }
`;
const TextField = styled.input`
  border: 1px solid #022334;
  width: 100%;
  border-radius: 3px;
  font-size: 17px;
  height: 50px;
  line-height: 25px;
  font-family: "outfit_light";
  letter-spacing: 1px;
  color: #eeeeee;
  background: #042941;
  padding-left: 10px;
  padding-right: 20px;
  /* outline-color: #0884df !important; */
  outline: none;
  margin-bottom: 5px;
  &:hover {
    border: 1px solid #0883e0;
  }
  @media all and (max-width: 1380px) {
    height: 50px;
    margin-bottom: 7px;
  }
  @media all and (max-width: 980px) {
    font-size: 16px;
  }
  @media all and (max-width: 768px) {
    margin-left: 17px;
    width: 90%;
  }
  @media all and (max-width: 640px) {
    width: 100%;
    margin-left: 0;
  }
`;
const Attachment = styled.div`
  width: 100%;
  height: 55px;
  position: relative;
  background: #042941;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  padding: 0 10px;
  @media all and (max-width: 1366px) {
    height: 50px;
    padding: 0;
  }
  @media all and (max-width: 480px) {
    height: 45px;
  }
`;
const Icon = styled.div`
  width: 100%;
  cursor: pointer;
  /* margin: 10px; */
  &.img {
    width: 100%;
    display: block;
  }
  @media all and (max-width: 980px) {
    width: 35px;
  }
  @media all and (max-width: 640px) {
    width: 33px;
  }
`;
const TextArea = styled.textarea`
  resize: none;
  border: 1px solid #022334;
  width: 100%;
  height: 120px;
  line-height: 25px;
  border-radius: 3px;
  padding: 10px;
  letter-spacing: 1px;
  font-family: "outfit_light";
  background: #042941;
  color: #eeeeee;
  font-size: 17px;
  @media all and (max-width: 980px) {
    font-size: 16px;
  }
`;
const CoverSubmit = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 40px;
`;
const Submit = styled.span`
  background: linear-gradient(255.47deg, #0881e0 0%, #05e8ba 102.09%);
  color: #fff;
  letter-spacing: 0.04em;
  :hover {
    color: #fff !important;
  }
  cursor: pointer;
  width: 155px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 2px; */
  /* @media all and (max-width: 1366px) {
    height: 40px;
  } */
`;
const Cancel = styled.div`
  background: #fff;
  color: #0880e0;
  border: 1px solid #0880e0;
  margin-right: 15px;
  width: 155px;
  height: 49px;
  display: flex;
  letter-spacing: 0.04em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Frame = styled.div`
  padding: 4px;
  border-radius: 3px;
`;