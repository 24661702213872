import React, { useContext, useRef, useState } from "react";

// packages
import styled from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";

// configs and context
import { accountsConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";

// components
import OtpModal from "../modal/OtpModal";
import SuccessModal from "../modal/SuccessModal";

// healpers
import ButtonLoader from "../../ui/general/helpers/ButtonLoader";

function Login({
  setActive,
  setLogin,
  inputValue,
  setInputValue,
  OTP,
  setOTP,
}) {
  const {
    dispatch
  } = useContext(Context);

  const [isShow, setIsShow] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [userInputValue, setUserInputValue] = useState();
  const [isError, setError] = useState(false);
  const [error, setErrorMsg] = useState("Enter a valid email or phone number");
  const [isLoading, setLoading] = useState(false);
  const [showOtp, setShowOtp] = useState("");
  const [border, setBorder] = useState("");

  const handleModal = () => {
    setIsModal(!isModal);
    setOTP("");
  };
  const handleSuccessModal = () => {
    setIsShow(!isShow);
  };
  const loginVerification = (e) => {
    if (inputValue.length >= 1) {
      handleOtpVerification(e);
    } else {
      setError("Please enter your email/phone");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleOtpVerification(e);
    } else if (e.keyCode === 69) {
      e.preventDefault();
    } else if(e.code === "Space") {
      e.preventDefault();
    }
  };
  //recaptcha
  const recaptchaRef = useRef(0);
  const verificationId=(e)=>{
    if(!isModal && e.target.value.includes("@")){
      setInputValue(e.target.value);
    }else if (!isModal && e.target.value.includes("+91")){
      setInputValue(e.target.value.slice(-10));
    }else{
      setInputValue(e.target.value);
    }
  }
  //-----------------------------login verification-----------------
  const handleOtpVerification = async (e) => {
    e.preventDefault();

    if (inputValue.includes("@")) {
      const token = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();
      setLoading(true);

      accountsConfig
        .post("api/v1/users/login/user/", {
          email: inputValue,
          "g-recaptcha-response": token,
        })
        .then((response) => {
          const { StatusCode, data } = response.data;
          if (StatusCode === 6000) {
            setLoading(false);
            setIsModal(true);
            setError(false);
            setShowOtp(data.otp);
            setUserInputValue(data.email);
            dispatch({
              type: "UPDATE_USER_DATA",
              user_data: {
                email: inputValue,
              },
            });
          } else {
            setError(true);
            setLoading(false);
            if (data.title === "Failed") {
              setErrorMsg(data.message);
            } else if (data.title === "Validation Error") {
              setErrorMsg("Enter a valid email or phone number");
            }
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          // setError(true)
        });
    } else {
      if (inputValue.length === 10) {
        setLoading(true);
        const token = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();
        accountsConfig
          .post("api/v1/users/login/user/", {
            country_web_code: "IN",
            phone: inputValue,
            "g-recaptcha-response": token,
          })
          .then((response) => {
            const { StatusCode, data } = response.data;
            if (StatusCode === 6000) {
              setLoading(false);
              setIsModal(true);
              setError(false);
              setShowOtp(data.otp);
              setUserInputValue(data.phone);
              dispatch({
                type: "UPDATE_USER_DATA",
                user_data: {
                  country_web_code: "IN",
                  "g-recpatcha-response": token,
                  phone: inputValue,
                  // user_id: userId,
                },
              });
            } else if (StatusCode === 6001) {
              setError(true);
              setLoading(false);
              if (data.title === "Failed") {
                setErrorMsg(data.message);
              } else if (data.title === "Validation Error") {
                setErrorMsg("Enter a valid email or phone number");
              }
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            // setError(true)
          });
      } else {
        setError("Enter a valid email or phone number");
      }
    }
  };
  //------------------------------------------------------------------------------
  return (
    <Container className="card" id="login">
      <Logo>
        <img
          src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/login-logo.svg"
          alt="Logo"
        />
      </Logo>
      <TextHead>Welcome back</TextHead>
      <SubHead>Welcome! Please enter your details</SubHead>
      <Form>
        <Cover>
          <EmailText>Enter email or phone number</EmailText>
          <EmailCover 
          onClick={()=> setBorder("click")}
          className={border === "click" ? "active" : ""}
          >
            <Email
              type="text"
              placeholder="Email/ Phone number"
              disabled={isModal && "disabled"}
              onChange={(e) => {
                // setInputValue(e.target.value);
                verificationId(e);
                setError(false);
              }}
              // value={userInputValue}
              onKeyPress={(e) => handleKeyPress(e)}
            />
          </EmailCover>
          <ErrorMessage>
            {isError && (
              <span>
                {!inputValue ? "Please enter your email/phone" : error}
              </span>
            )}
          </ErrorMessage>
        </Cover>
        <Button
          onClick={(e) => {
            loginVerification(e);
          }}
        >
          {isLoading ? <ButtonLoader /> : <span>Login</span>}
        </Button>
        <SubText>
          Don't have an account?{" "}
          <span onClick={() => setActive("Sign Up")}> Sign Up</span>
        </SubText>
        <ReCAPTCHA
          ref={recaptchaRef}
          //This ref can be used to call captcha related functions in case you need.
          sitekey="6LeTewMiAAAAAOJJMNKPlq3oG07U1XylrqchzXnJ"
          size="invisible"
          badge="bottomleft"
        />
      </Form>
      <OtpModal
        isModal={isModal}
        setModal={setIsModal}
        isShow={isShow}
        setShow={setIsShow}
        handleModal={handleModal}
        inputValue={inputValue}
        setInputValue={setInputValue}
        userInputValue={userInputValue}
        setUserInputValue={setUserInputValue}
        OTP={OTP}
        setOTP={setOTP}
        showOtp={showOtp}
        setShowOtp={setShowOtp}
      />
      <SuccessModal
        setLogin={setLogin}
        isShow={isShow}
        setShow={setIsShow}
        handleSuccessModal={handleSuccessModal}
      />
    </Container>
  );
}

export default Login;

const Container = styled.div`
  background: #fff;
  padding: 70px 40px;
  border-radius: 2px;
  @media all and (max-width: 1380px) {
    padding: 65px 40px;
  }
  @media all and (max-width: 1280px) {
    padding: 40px;
  }
  @media all and (max-width: 1080px) {
    padding: 50px 45px;
  }
  @media all and (max-width: 980px) {
    padding: 30px 20px;
  }
  @media all and (max-width: 768px) {
    padding: 30px 25px;
  }
  @media all and (max-width: 640px) {
    padding: 40px 20px;
  }
  @media all and (max-width: 480px) {
    padding: 30px 15px;
  }
`;
const TextHead = styled.h3`
  background: linear-gradient(123.89deg, #0e94f3 -23.5%, #05e8ba 111.92%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 26px;
  text-align: center;
  font-family: "outfit_regular";
  margin-bottom: 15px !important;
  @media all and (max-width: 1366px) {
    margin-bottom: 2px;
  }
  @media all and (max-width: 980px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
  @media all and (max-width: 640px) {
    font-size: 22px;
    margin-bottom: 8px;
  }
  @media all and (max-width: 480px) {
    font-size: 20px;
  }
`;
const SubHead = styled.h5`
  margin-bottom: 30px !important;
  text-align: center;
  color: #929292;
  font-size: 15px;
  font-family: "outfit_light";
  @media all and (max-width: 980px) {
    margin-bottom: 20px;
    font-size: 15px;
  }
  @media all and (max-width: 640px) {
    font-size: 17px;
  }
  @media all and (max-width: 480px) {
    font-size: 13px;
  }
`;
const EmailCover = styled.div`
  /* border: 1px solid grey; */
  /* border: none; */
  height: 45px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  &:hover {
    border: 1px solid #0883e0;
  }
  &.active{
    border: 1px solid #0883e0;
  }
  @media all and (max-width: 480px) {
    height: 40px;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 7px;
  }
`;
const Form = styled.form``;
const ErrorMessage = styled.div`
  width: 100%;
  height: 40px;
  font-size: 13px;
  color: red;
  font-family: "outfit_light";
  @media all and (max-width: 480px) {
    margin-left: 15px;
  }
  @media all and (max-width: 360px) {
    height: 30px;
  }
`;
const Logo = styled.div`
  width: 20%;
  margin: 0 auto;
  margin-bottom: 20px;
  & img {
    width: 100%;
    display: block;
  }
  @media all and (max-width: 980px) {
    width: 18%;
  }
  @media all and (max-width: 480px) {
    margin-bottom: 15px;
  }
  @media all and (max-width: 360px) {
    margin-bottom: 10px;
  }
`;
const Cover = styled.div``;
const EmailText = styled.h6`
  color: #929292;
  margin: 5px 0px !important;
  font-size: 14px;
  font-family: "outfit_light";
  @media all and (max-width: 480px) {
    margin-left: 15px !important;
  }
`;
const Email = styled.input`
  width: 100%;
  color: #707070;
  font-family: "outfit_light";
  height: 100%;
  outline: none;
  font-size: 14px;
  padding: 0 14px;
`;
const Button = styled.div`
  transition: all 0.5s;
  background: linear-gradient(255.47deg, #0881e0 0%, #05e8ba 102.09%);
  width: 100%;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  margin: 10px auto;
  font-size: 15px;
  & span {
    font-family: "outfit_light";
    letter-spacing: 0.8px;
  }
  &:hover {
    transition: all 0.5s;
    background: linear-gradient(255.47deg, #05e8ba 0%, #0881e0 102.09%);
  }
  @media all and (max-width: 980px) {
    margin: 0 auto;
  }
  @media all and (max-width: 640px) {
    margin-bottom: 10px;
  }
  @media all and (max-width: 480px) {
    font-size: 17px;
    width: 90%;
    /* height: 35px; */
    font-size: 14px;
  }
`;
const SubText = styled.span`
  color: #858585;
  margin-top: 10px;
  font-size: 14px;
  font-family: 'outfit_light';
  & span {
    color: #0881e0;
    cursor: pointer;
    font-family: "outfit_medium";
  }
  @media all and (max-width: 980px) {
    margin: 0 auto;
    display: block;
  }
  @media all and (max-width: 480px) {
    font-size: 14px;
    width: 90%;
  }
`;
