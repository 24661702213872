import React, { useState, Suspense, useContext } from "react";
import "../../../assets/css/style.css";
import { lazy } from "react";

// packages
import { Navigate, Route, Routes} from "react-router-dom";

// components
import Header from "../../ui/general/includes/Header";
import Footer from "../../ui/general/includes/Footer";
import SectionLoader from "../../ui/general/helpers/SectionLoader";
import { Context } from "../../contexts/Store";
import PrivateRoute from "../routes/PrivateRoute";

const EnquiryManagementSystem = lazy(() =>
    import("../../ui/screens/EnquiryManagementSystem")
);
const TenderManagementDetails = lazy(() => import("../../ui/screens/TenderManagemetDetails"));
const QuotationPage = lazy(() => import("../../ui/screens/QuotationChatSystem/QuotationPage"));
const EnquiryPage = lazy(() => import("../../ui/screens/EnquiryPage"));
const ProjectCount = lazy(() => import("../../ui/screens/ProjectCount"));
const OurService = lazy(() => import("../../ui/screens/OurService"));
const ProjectDetail = lazy(() => import("../../ui/screens/ProjectDetail"));
const OrderSummary = lazy(() => import("../../ui/screens/OrderSummary"));
const ProjectDetails = lazy(() => import("../../ui/screens/ProjectDetails"));
const AddEnquiry = lazy(() => import("../../ui/screens/AddEnquiry"));
const ProfileLandingPage = lazy(() =>
    import("../../ui/screens/Profile/ProfileLandingPage")
);

function AppRouter({ scroll, setScroll }) {
    const [getId, setGetId] = useState();
    const [click, setClick] = useState("pending");
    const [fromDate, setFromDate] = useState("");
    const [isprofile, setIsProfile] = useState(false);
    const [toDate, setTodate] = useState("");
    const [sectionHider, setSectionHider] = useState(false);
    const {
        state: { user_data },
    } = useContext(Context);
    const hide = user_data.isLogin;
    return (
        <>
            <Suspense fallback={<SectionLoader />}>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <Header
                                isprofile={isprofile}
                                setIsProfile={setIsProfile}
                                sectionHider={sectionHider}
                            />
                        }
                    >
                        <Route
                            path="/"
                            element={<Navigate to="tender-management" />}
                        />
                        {/* <Route
                            path="/"
                            element={<Navigate to="enquiry-management" />}
                        /> */}
                        {/* <Route
                            path="enquiry-management"
                            element={
                                <PrivateRoute>
                                    <EnquiryManagementSystem
                                        fromDate={fromDate}
                                        setFromDate={setFromDate}
                                        toDate={toDate}
                                        setTodate={setTodate}
                                        click={click}
                                        setClick={setClick}
                                        getId={getId}
                                        setGetId={setGetId}
                                        setSectionHider={setSectionHider}
                                    />
                                </PrivateRoute>
                            }
                        /> */}
                        <Route
                            path="tender-management"
                            element={
                                <PrivateRoute>
                                    <EnquiryManagementSystem
                                        fromDate={fromDate}
                                        setFromDate={setFromDate}
                                        toDate={toDate}
                                        setTodate={setTodate}
                                        click={click}
                                        setClick={setClick}
                                        getId={getId}
                                        setGetId={setGetId}
                                        setSectionHider={setSectionHider}
                                    />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="tender-management/:id"
                            element={
                                <PrivateRoute>
                                    <TenderManagementDetails
                                        fromDate={fromDate}
                                        setFromDate={setFromDate}
                                        toDate={toDate}
                                        setTodate={setTodate}
                                        click={click}
                                        setClick={setClick}
                                        getId={getId}
                                        setGetId={setGetId}
                                        setSectionHider={setSectionHider}
                                    />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="tender-management/:id/chat/:rm_id"
                            element={
                                <PrivateRoute>
                                    <QuotationPage
                                        click={click}
                                        setClick={setClick}
                                        getId={getId}
                                        setGetId={setGetId}
                                        fromDate={fromDate}
                                        setFromDate={setFromDate}
                                        toDate={toDate}
                                        setTodate={setTodate}
                                    />
                                </PrivateRoute>
                            }
                        />
                        {/* <Route
                            path="enquiry-management/:rm_id"
                            element={
                                <PrivateRoute>
                                    <EnquiryPage
                                        click={click}
                                        setClick={setClick}
                                        getId={getId}
                                        setGetId={setGetId}
                                        fromDate={fromDate}
                                        setFromDate={setFromDate}
                                        toDate={toDate}
                                        setTodate={setTodate}
                                    />
                                </PrivateRoute>
                            }
                        /> */}
                        <Route
                            path="faq"
                            element={
                                <PrivateRoute>
                                    <ProjectCount />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="add-enquiry"
                            element={
                                <PrivateRoute>
                                    <AddEnquiry
                                        setScroll={setScroll}
                                        scroll={scroll}
                                    />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="services"
                            element={
                                <PrivateRoute>
                                    <OurService />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="projectdetail"
                            element={
                                <PrivateRoute>
                                    <ProjectDetail />
                                </PrivateRoute>
                            }
                        />
                        <Route path="ordersummary" element={<OrderSummary />} />
                        <Route
                            path="projectdetails"
                            element={<ProjectDetails />}
                        />
                        {/* <Route path='profile' element={<Profile />} /> */}
                        <Route
                            path="profile/"
                            element={
                                <PrivateRoute>
                                    <ProfileLandingPage />
                                </PrivateRoute>
                            }
                        />
                        <Route path="contact" element={<Footer />} />
                    </Route>
                </Routes>
                {hide ? "" : <Footer />}
            </Suspense>
        </>
    );
}

export default AppRouter;
