import { BrowserRouter } from 'react-router-dom';
import './App.css';
import './assets/css/style.css'
import Store from './components/contexts/Store';
import MainRouter from './components/routing/routers/MainRouter';

function App() {


  return (
    <Store>
      <BrowserRouter>
        <MainRouter />
      </BrowserRouter>
    </Store>
  );
}

export default App;
