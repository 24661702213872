import React, { createContext, useReducer } from "react";
import Reducer from "./Reducer";

const initialState = {
	user_data: {
		isLogin: false,
		access_token: "",
		name: "",
		email: "",
		country_web_code: "",
		phone: "",
		student_id: "",
		user_id: "",
		enquiry_login: "Login",
		roles: "",
	},
	enquiry_datas: {
		category: "",
		subject: "",
		description: "",
		attachments: [],
		formData: "",
		fromAddEnquiry: false,
	},
	scroll: {
		scroll_id: "",
		scroll_top: "",
	},
};
const Store = ({ children }) => {
	const [state, dispatch] = useReducer(Reducer, initialState);
	return (
		<Context.Provider value={{ state, dispatch }}>
			{children}
		</Context.Provider>
	);
};

export const Context = createContext(initialState);

export default Store;
