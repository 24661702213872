const ChiefReducer = (state, action) => {
	switch (action.type) {
		case "UPDATE_USER_DATA":
			const user_data = { ...state.user_data, ...action.user_data };
			localStorage.setItem("user_data", JSON.stringify(user_data));
			return {
				...state,
				user_data: user_data,
			};
		case "UPDATE_ENQUIRY_DATA":
			const enquiry_datas = { ...state.enquiry_datas, ...action.enquiry_datas };
			localStorage.setItem("enquiry_datas", JSON.stringify(enquiry_datas));
			return {
				...state,
				enquiry_datas: enquiry_datas,
			};
		case "UPDATE_SCROLL":
			const scroll = { ...state.scroll, ...action.scroll };
			// localStorage.setItem("enquiry_datas", JSON.stringify(enquiry_datas));
			return {
				...state,
				scroll: scroll,
			};
		default:
			return state;
	}
};

export default ChiefReducer;
