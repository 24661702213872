import React, { useContext, useEffect, useRef, useState } from "react";

// Packages
import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";
import styled from "styled-components";
import $ from 'jquery'
import { useLocation } from 'react-router-dom';

// Images
import { Context } from "../../../contexts/Store";

function Header({ isprofile, setIsProfile }) {
	const [active, setActive] = useState("HOME");
	const [isShow, setIsShow] = useState(false);
	const [dropDown, setDropDown] = useState(false);
	const [isClick, setIsClick] = useState(false);
	const location = useLocation()
	const {
		state: { user_data },
	} = useContext(Context);
	const isLogin = user_data.isLogin;

	useEffect(() => {
		if (isClick) {
			$("html").addClass("modal-enabled");
		} else {
			$("html").removeClass("modal-enabled");
		}
	}, [isClick]);

	//----------OutsideClick---------------------------------------------------
	function useOutsideClick(ref) {
		useEffect(() => {
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					  setIsShow(false);
					// setIsProfile(false);
				}
			}
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref]);
	}
	const wrapperRef = useRef(null);
	useOutsideClick(wrapperRef);
	//------------------------------------------------------------------------------------

	return (
		<>
			<Container>
				<Wrapper className="wrapper">
					<Left>
						<h1>
							<ImageContainer to={isLogin ? "/tender-management" : "/"}>
								<img src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/headlogo.svg" alt="Logo imag" />
							</ImageContainer>
						</h1>
					</Left>
					<Right>
						{/* {isClick ? (
							""
						) : (
							<Nav>
								<Link to="/tender-management">
									<Item
										// to="/tender-management"
										onClick={() => setActive("HOME")}
										className={
											active === "HOME" ? "active" : ""
										}
									>
										HOME
									</Item>
								</Link>
								<Item
									// to="/web/faq"
									onClick={() => setActive("Faq")}
									className={active === "Faq" ? "active" : ""}
								>
									FAQ
								</Item>
								<Item
									// to="/web/services"
									onClick={() => setActive("Services")}
									className={
										active === "Services" ? "active" : ""
									}
								>
									SERVICES
								</Item>
								{!isprofile && (
									<ItemFoot
										href="#foot"
										onClick={() => setActive("Contact")}
										className={
											active === "Contact" ? "active" : ""
										}
									>
										CONTACT US
									</ItemFoot>
								)}
							</Nav>
						)} */}
						<div class="three col">
							{
								isLogin &&
								<Hamburger
									className={isClick ? "is-active" : "hamburger"}
									onClick={() => setIsClick(!isClick)}
									id="hamburger-1"
								>
									<span class="line"></span>
									<span class="line"></span>
									<span class="line"></span>
								</Hamburger>
							}
							<HamburgerCover className={isClick && "hamdown"}>
								<HamList>
									<Link to="/tender-management">
										<HamItem
											onClick={() => { setIsClick(!isClick); setActive("HOME") }}
											className={
												active === "HOME" ? "active" : ""
											}
										>
											HOME
										</HamItem>
									</Link>
									<Line>
										<img src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/narrow.svg" alt="designline" />
									</Line>
									<Link to="/profile">
										<HamItem
											onClick={() => { setIsClick(!isClick); setActive("PROFILE") }}
											className={
												active === "PROFILE" ? "active" : ""
											}
										>
											PROFILE
										</HamItem>
									</Link>
									<Line>
										<img src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/narrow.svg" alt="designline" />
									</Line>
									{/* <HamItem
										// to="/faq"
										// href="#faq"
										onClick={() => setActive("Faq")}
										className={
											active === "Faq" ? "active" : ""
										}
									>
										FAQ
									</HamItem>
									<Line>
										<img src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/narrow.svg" alt="designline" />
									</Line> */}
									{/* <HamItem
										// to="/services"
										// href="#service"
										onClick={() => setActive("Services")}
										className={
											active === "Services"
												? "active"
												: ""
										}
									>
										SERVICES
									</HamItem>
									<Line>
										<img src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/narrow.svg" alt="designline" />
									</Line> */}
									{/* <HamItem
										// to="/contact"
										// href="#foot"
										onClick={() => setActive("Contact")}
										className={
											active === "Contact" ? "active" : ""
										}
									>
										CONTACT US
									</HamItem>
									<Line>
										<img src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/narrow.svg" alt="designline" />
									</Line> */}



									{/* <HamItems
										// to=""
										// href="#profile"
										onClick={() => {
											setDropDown(!dropDown);
											setActive("Accounts");
										}}
										className={
											active === "Accounts"
												? "active"
												: ""
										}
									>
										ACCOUNTS
									</HamItems> */}

									<Accounts className={dropDown && "subdown"}>
										<Sub>
											<Ham
												// to="/web/profile"
												onClick={() => {
													setActive("Profile");
													setIsClick(!isClick);
												}}
												className={
													active === "Profile"
														? "active"
														: ""
												}
											>
												PROFILE
											</Ham>

											{/* <Ham
												// to="/setting"
												onClick={() => {
													setActive("Setting");
													setIsClick(!isClick);
												}}
												className={
													active === "Setting"
														? "active"
														: ""
												}
											>
												SETTINGS
											</Ham> */}

											{/* <Ham
												// to="/privacy-policy"
												onClick={() => {
													setActive("Privacy Policy");
													setIsClick(!isClick);
												}}
												className={
													active === "Privacy Policy"
														? "active"
														: ""
												}
											>
												PRIVACY POLICY
											</Ham> */}

											{/* <Ham
												// to="/support"
												onClick={() => {
													setActive("Support");
													setIsClick(!isClick);
												}}
												className={
													active === "Support"
														? "active"
														: ""
												}
											>
												SUPPORT
											</Ham> */}

											<Lines />
										</Sub>
									</Accounts>
								</HamList>
							</HamburgerCover>
						</div>

						{!isLogin ? (
							""
						) : (
							<Account>
								{/* <Bell
									onClick={() => setIsShow(!isShow)}
									ref={wrapperRef}
									className={isShow && "active"}
								>
									{isShow ? (
										<img src={white} alt="White icon" />
									) : (
										<img
											src={notification}
											alt="Blue icon"
										/>
									)}
								</Bell> */}

								<Profile
									to="/tender-management"
									type={isprofile}
									onClick={() => setIsProfile(false)}
								>
									{location.pathname === "/tender-management" ? (
										<img src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/home-active.svg" alt="User icon" />
									) : (
										<img
											src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/home.svg"
											alt="User inactive icon"
										/>
									)}
								</Profile>
								<Profile
									to="/profile"
									type={isprofile}
									onClick={() => setIsProfile(true)}
								>
									{location.pathname === "/profile" ? (
										<img src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/profile-active.svg" alt="User icon" />
									) : (
										<img
											src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/profile-inactive.svg"
											alt="User inactive icon"
										/>
									)}
								</Profile> 
								<DropBox type={isShow}
									className={isShow && "dropdown"}
									ref={wrapperRef}
								>
									<UL>
										<Alert>No Notification Found</Alert>
										{/* <Li>
											Bridge construction meeting
											<ActiveTime>
												30 seconds ago
											</ActiveTime>
										</Li>
										<Li>
											House plan meeting
											<ActiveTime>
												10 minutes ago
											</ActiveTime>
										</Li>
										<Li>
											New enquiry by Arshal
											<ActiveTime>
												12 minutes ago
											</ActiveTime>
										</Li>
										<Li>
											House interior design
											<ActiveTime>
												30 minutes ago
											</ActiveTime>
										</Li> */}
									</UL>
									{/* <Bar>All notifications</Bar> */}
								</DropBox>
							</Account>
						)}
					</Right>
				</Wrapper>
			</Container>
			{isClick ? (
				<Overlay onClick={() => setIsClick(!isClick)} className={isClick && "hamdown"}></Overlay>
			) : (
				""
			)}
			<Outlet />
		</>
	);
}

export default Header;

const Container = styled.div`
	padding: 24px 0;
	display: flex;
	height: 90px;
	backdrop-filter: blur(10px);
	justify-content: space-between;
	align-items: center;
	position: fixed;
	width: 100%;
	background: #ffff;
	top: 0;
	z-index: 100;
	@media all and (max-width: 640px) {
		height: 80px;
	}
	@media all and (max-width: 980px) {
		padding: 15px 0;
	}
	@media all and (max-width: 640px) {
		padding: 10px 0;
	}
	@media all and (max-width: 480px){
		height:75px;
	}
`;
const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const Left = styled.div`
    width: 40%;
    & h1 {
        margin-bottom: 0px;
        width: 180px;
        @media all and (max-width: 1080px) {
            width: 175px;
        }
        @media all and (max-width: 980px) {
            width: 170px;
        }
        @media all and (max-width: 768px) {
            width: 165px;
        }
        @media all and (max-width: 640px) {
            width: 160px;
        }
        @media all and (max-width: 480px) {
            width: 155px;
        }
        @media all and (max-width: 360px) {
            width: 150px;
        }
    }
`;
const ImageContainer = styled(Link)`	
	cursor: pointer;
	& img {
		display: block;
		width: 100%;
	}
	
`;
const Right = styled.div`
	width: 60%;
	display: flex;
	justify-content: flex-end;
	@media all and (max-width: 768px) {
		width: 70%;
	}
`;

const Account = styled.div`
	display: flex;
	justify-content: space-between;
	width: 110px;
	height: 43px;
	align-items: center;
	position: relative;
	margin-left: 20px;
	@media all and (max-width: 980px){
		width:80px;
	}
	@media all and (max-width: 640px) {
		display: none;
	}
`;

const Profile = styled(Link)`
	width: 40px;
	height: 40px;
	cursor: pointer;
	padding: 4px;
	box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.06);
	border-radius: 50%;
	background: #fff;
	color: #03a9f4;
	&:hover {
		box-shadow: 0px 4px 18px 10px rgba(0, 0, 0, 0.06);
	}
	& img {
		width: 100%;
		display: block;
	}
	@media all and (max-width: 980px) {
		width: 35px;
		height: 35px;
	}
`;
const DropBox = styled.div`
	position: absolute;
	width: 280px;
	max-height: 0px;
	overflow: hidden;
	background: #f5f7fb;
	right: 85px;
	top: 50px;
	box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.06);
	z-index: 1;
	border-radius: 2px;
	transition: 0.4s ease;
	&.dropdown {
		transition: 0.4s ease;
		max-height: 400px;
		width: 290px;
		opacity: 1;
		overflow: ${({ type }) => type ? "" : "hidden"};
        cursor: pointer;
	}
	@media all and (max-width: 768px) {
		right: 76px;
	}
	@media all and (max-width: 480px) {
		right: -9px;
	}
`;
const UL = styled.ul`
	height: 250px;
	position: relative;
`;
const Hamburger = styled.div`
	display: none;
	cursor: pointer;
	@media all and (max-width: 640px) {
		display: block;
	}
`;

const HamburgerCover = styled.div`
	background-color: #fff !important;
	overflow: hidden;
	z-index: 1;
	position: fixed;
	top: 75px;
	left: 0;
	max-height: 0;
	text-align: center;
	transition: 0.4s ease;
	opacity: 0;
	&.hamdown {
		right: 0px;
		text-align: center;
		opacity: 1;
		max-height: 240px;
		transition: 0.4s ease;
		z-index: 100;

	}
	@media all and (max-width: 640px) {
		top: 69px;
	}
	@media all and (max-width: 480px) {
		top: 70px;
	}
`;
const HamList = styled.div`
	padding: 52px 0;
	@media all and (max-width: 640px) {
		padding: 20px 0;
	}
`;
const HamItem = styled.div`
	cursor: pointer;
	color: #4a4a4a91;
	font-family: "outfit_medium";
	position: relative;
	padding: 7px 0;
	font-size: 13px;
	&.active {
		background: linear-gradient(255.47deg, #0881e0 0%, #05e8ba 102.09%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		transition: 0.4s ease;
		text-fill-color: transparent;
	}
`;
const Ham = styled.div`
	cursor: pointer;
	color: #4a4a4a91;
	font-family: "outfit_medium";
	display: block;
	position: relative;
	padding: 10px 0;
	font-size: 12px;
	&.active {
		background: linear-gradient(255.47deg, #0881e0 0%, #05e8ba 102.09%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		transition: 0.4s ease;
		text-fill-color: transparent;
	}
	@media all and (max-width: 640px) {
		padding: 5px 0;
	}
`;
const Lines = styled.div`
	width: 60%;
	margin: 0 auto;
	height: 10px;
`;
const Line = styled.div`
	width: 60%;
	margin: 0 auto;
	height: 40px;
	& img {
		width: 100%;
		display: block;
	}
	@media all and (max-width: 640px) {
		height: 20px;
	}
`;
const Overlay = styled.div`
	background: rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(2px);
	position: fixed;
	z-index: 6;
	left: 0;
	top: 0px;
	width: 100%;
	min-height: 100vh;
	max-height: 100vh;
	filter: blur(1px);
`;
const Accounts = styled.div`
	overflow: hidden;
	z-index: 1;
	position: fixed;
	max-height: 0px;
	font-size: 12px;
	transition: 0.4s ease;
	width: 100%;
	&.subdown {
		background: #fff;
		max-height: 300px;
		text-align: center;
		transition: 0.4s ease;
	}
`;
const Sub = styled.div`
	transform: translateX(240px);
	width: 50%;
	text-align: left;
	@media all and (max-width: 480px) {
		transform: translateX(180px);
	}
	@media all and (max-width: 360px) {
		transform: translateX(155px);
	}
`;
const Alert = styled.div`
	position: absolute;
	top: 120px;
	left: 70px;
	font-size: 15px;
	color: #ababab;
`;
