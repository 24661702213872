import React, { useState } from "react";

//Packages
import "../../../assets/css/bootstrap.min.css";
import {
    LoadCanvasTemplateNoReload,
} from "react-simple-captcha";
import styled from "styled-components";

function CaptchTest({ isCaptchaValid, captchaMsg, border, setBorder }) {
    const [input, setInput] = useState("");
    return (
        <div>
            <Div>
                <Left onClick={() => setInput("")}>
                    <LoadCanvasTemplateNoReload />
                </Left>

                <Cover>
                    <Right
                        id="captcha"
                        onClick={() => setBorder("captcha")}
                        className={border === "captcha" ? "active" : ""}
                    >
                        <input
                            placeholder="Enter captcha"
                            id="user_captcha_input"
                            name="user_captcha_input"
                            type="text"
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                        />
                    </Right>
                    <ErrorMessage className="captcha">
                        {!isCaptchaValid && captchaMsg && (
                            <>
                                <span>{captchaMsg}</span>
                            </>
                        )}
                    </ErrorMessage>
                </Cover>
            </Div>
        </div>
    );
}

export default CaptchTest;

const Div = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media all and (max-width: 980px) {
        /* display:block; */
    }
    @media all and (max-width: 480px) {
        display: block;
    }
`;
const ErrorMessage = styled.div`
    color: red;
    font-size: 13px;
    width: 100%;
    height: 30px;
    font-family: "outfit_light";
`;
const Cover = styled.div`
    width: 45%;
    @media all and (max-width: 1080px) {
        width: 50%;
    }
    @media all and (max-width: 980px) {
        width: 100%;
    }
    @media all and (max-width: 640px) {
        /* width:100%; */
    }
`;
const Left = styled.div`
    border: 1px solid #e0e0e0;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 45px 0 0px;
    margin-right: 20px;
    width: 50%;
    position: relative;
    background-repeat: no-repeat;
    background-position: center right 30px;
    @media all and (max-width: 1400px) {
        margin-right: 18px;
    }
    @media all and (max-width: 1080px) {
        padding: 9px 18px 0 15px;
        margin-right: 0px;
        width: 47%;
        margin-bottom: 10px;
    }
    @media all and (max-width: 980px) {
        width: 100%;
        margin-bottom: 15px;
        overflow: hidden;
    }
    @media all and (max-width: 640px) {
        width: 100%;
        margin-bottom: 20px;
        margin-right: unset;
        margin-top: 20px;
    }
`;
const Right = styled.div`
    border: 1px solid #e0e0e0;
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    :hover {
        border: 1px solid #0883e0;
    }
    &.active {
        border: 1px solid #0883e0;
    }
    input {
        padding: 7px 25px;
        outline: none;
        color: #707070;
        width: 100%;
        font-family: "outfit_light";
        font-size: 14px;
        @media all and (max-width: 980px) {
            padding: 7px 20px;
        }
        @media all and (max-width: 640px) {
            font-size: 14px;
        }
        @media all and (max-width: 480px) {
            padding: unset;
        }
        @media all and (max-width: 360px) {
            font-size: 13px;
            padding-left: 12px;
        }
    }
    @media all and (max-width: 980px) {
        width: 100%;
        padding: 0 10px;
    }
`;
