import React, { useEffect } from "react";
//package
import { Link } from "react-router-dom";
import styled from "styled-components";
import $ from 'jquery'

function SuccessModal({
    handleSuccessModal, isShow, setLogin, modalType, setScrollto
}) {
    useEffect(() => {
        if (isShow) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isShow]);
    return (
        <MainContainer>
            {isShow ? (
                <Overlay onClick={() => handleSuccessModal()}></Overlay>
            ) : ("")}
            <BackContainer style={{ transform: isShow && "scale(1,1)" }}>
                <Modal type="textarea">
                    <Container>
                        <TopSection>
                            <CoverText>
                                <CoverImage>
                                    <img src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/pending.svg" alt="Tick icon" />
                                </CoverImage>
                                {
                                    modalType ?
                                        <Text>Pending</Text> :
                                        <Text>Verification Successfull</Text>
                                }
                            </CoverText>
                            <ImageContainer onClick={handleSuccessModal}>
                                <img src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/close.svg" alt="Close icon" />
                            </ImageContainer>
                        </TopSection>
                        {
                            modalType ?
                                <TextLen>You need to register for successful submission of your enquiry.</TextLen> :
                                <TextLen>You are successfully verified, let’s get started</TextLen>
                        }
                        <Bottom>
                            <Submit to={modalType ? "/signup" : "/tender-management"} onClick={() => { setScrollto(true); handleSuccessModal() }}>{modalType ? "Go to Login" : "Continue"}</Submit>
                        </Bottom>
                    </Container>
                </Modal>
            </BackContainer>
        </MainContainer>
    );
}
export default SuccessModal;

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
    right: 0;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 490px;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    transition: 0.5s;
    z-index: 101;
    border-radius: 5px;
    background: #fff;
    @media all and (max-width: 1280px){
        width:520px;
    }
    @media all and (max-width:1080px){
        width:500px;
    }
    @media all and (max-width: 980px) {
        width: 650px;
    }
    @media all and (max-width: 768px) {
        width: 480px;
    }
    @media all and (max-width: 640px) {
        width: 400px;
    }
    @media all and (max-width: 480px) {
        width: 340px;
    }
    @media all and (max-width: 360px) {
        width: 300px;
    }
`;
const MainContainer = styled.div``;
const Container = styled.div`
    padding: 40px;
    @media all and (max-width: 640px){
        padding:30px;
    }
    @media all and (max-width: 480px){
        padding:15px;
    }
`;
const TopSection = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: center;
    border-bottom: 2px solid #E7E7E7;
`;
const Text = styled.h3`
    color: #0A4771;
    font-size: 20px ;
    margin-bottom: 20px !important;
    font-family: 'outfit_medium';
    @media all and (max-width: 640px){
        font-size:17px;
    }
`;
const ImageContainer = styled.div`
    width: 20px;
    cursor: pointer;
    margin-bottom: 20px;
    & img{
        display: block;
        width: 100%;
    }
`;
const Submit = styled(Link)`
    background: linear-gradient(123.89deg, #0E94F3 -23.5%, #05E8BA 111.92%);
    padding: 10px 25px;
    color: #fff !important;
    :hover{
		color: #fff !important;
	}
    border-radius:4px;
    cursor: pointer;
    @media all and (max-width: 480px){
        padding:8px 20px;
        font-size:15px;
    }
    @media all and (max-width: 360px){
      font-size:13px;
    }
`;
const CoverText = styled.div`
    display: flex;
    align-items: center;
`;
const CoverImage = styled.div`
    width: 40px;
    margin-right: 10px;
    margin-bottom: 20px;
    img{
        width: 100%;
        display: block;
    }
    @media all and (max-width: 480px){
        width:30px;
    }
`;
const TextLen = styled.p`
    margin-bottom: 50px;
    color: #6B6B6B;
    @media all and (max-width: 640px){
        font-size:13px;
    }
    @media all and (max-width: 480px){
        margin-bottom:30px;
    }
`;
const Bottom = styled.div`
    display: flex;
    justify-content: flex-end;
`;