import React, { lazy, Suspense, useContext, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Context } from "../../contexts/Store";
import SectionLoader from "../../ui/general/helpers/SectionLoader";
import Home from "../../ui/screens/Home";
import AppRoute from "../routes/AppRoute";
import AppRouter from "./AppRouter";
const HomePage = lazy(() => import("../../ui/screens/HomePage"));

function HomePageRouter({ scroll, setScroll }) {
    const { state: { user_data } } = useContext(Context);

    const [inputValue, setInputValue] = useState("");
    const [isActive, setActive] = useState(user_data.enquiry_login);
    return (
        <Suspense fallback={<SectionLoader />}>
            <Routes>
                <Route path="/" element={
                    <HomePage
                        scroll={scroll}
                        setScroll={setScroll}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        isActive={isActive}
                        setActive={setActive}
                    />
                } />

                <Route path="signup" element={
                    <Home
                        setScroll={setScroll}
                        scroll={scroll}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        isActive={isActive}
                        setActive={setActive}
                    />
                } />
                <Route path="*" element={<AppRouter />} />
            </Routes>
        </Suspense>
    );
}

export default HomePageRouter;
