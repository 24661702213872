import React, { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import SectionLoader from '../../ui/general/helpers/SectionLoader'
import Footer from '../../ui/general/includes/Footer'
import Header from '../../ui/general/includes/Header'
import AddEnquiry from '../../ui/screens/AddEnquiry'
const OurService = lazy(() => import('../../ui/screens/OurService'))
const ProfileLandingPage = lazy(() => import('../../ui/screens/Profile/ProfileLandingPage'))
const ProjectCount = lazy(() => import('../../ui/screens/ProjectCount'))

function WebRouter() {
    return (
        <>
            <Suspense fallback={<SectionLoader />}>
                <Routes>
                    <Route path="/" element={<Header />} >
                        <Route path="faq" element={<ProjectCount />} />
                        <Route path="services" element={<OurService />} />
                        <Route path="profile" element={<ProfileLandingPage />} />
                        <Route path="add-enquiry" element={<AddEnquiry />} />
                    </Route>
                </Routes>
                <Footer />
            </Suspense>
        </>
    )
}

export default WebRouter