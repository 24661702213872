import React, { useEffect } from "react";
import styled from "styled-components";
import $ from "jquery";

function CountryModal({
	handleCountryModal,
	isCountryModal,
	data,
	setWebCode,
	setPhoneCode,
	setCountryImage,
	setCountryValue
}) {
	useEffect(() => {
		if (isCountryModal) {
			$("html").addClass("modal-enabled");
		} else {
			$("html").removeClass("modal-enabled");
		}
	}, [isCountryModal]);
	return (
		<MainContainer>
			{isCountryModal ? (
				<Overlay onClick={() => handleCountryModal()}></Overlay>
			) : (
				""
			)}
			<BackContainer
				style={{ transform: isCountryModal && "scale(1,1)" }}
			>
				<Modal type="textarea">
					<Container>
						<Close onClick={() => handleCountryModal()}>
							<img src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/close.svg" alt="close" />
						</Close>
						<Select>
							<input
								type="search"
								placeholder="Search here"
								onChange={(e) =>
									setCountryValue(e.target.value)
								}
							/>
							<Cover>
								{data.map((item) => (
									<>
										<Option
											key={item.web_code}
											onClick={() => {
												setWebCode(item.web_code);
												setCountryImage(item.flag);
												setPhoneCode(item.phone_code);
											}}
										>
											<ImageContainer>
												<img src={item.flag} alt="flag"/>
											</ImageContainer>
											<span>
												{item.name} - {item.web_code}
											</span>
										</Option>
									</>
								))}
							</Cover>
						</Select>
					</Container>
				</Modal>
			</BackContainer>
		</MainContainer>
	);
}
export default CountryModal;

const BackContainer = styled.div`
	position: fixed;
	transition: 0.3s;
	transform: scale(0, 0);
	width: 100%;
	max-width: 300px;
	margin: 0 auto;
	right: 0;
	height: 100vh;
	z-index: 1000;
	left: 0;
	top: 0px;
`;
const Overlay = styled.div`
	background: rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(2px);
	position: fixed;
	z-index: 10;
	left: 0;
	top: 0px;
	width: 100%;
	min-height: 100vh;
	max-height: 100vh;
	filter: blur(1px);
`;
const Modal = styled.div`
	width: 90%;
	max-width: 350px;
	margin: 0 auto;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	transition: 0.5s;
	z-index: 101;
	border-radius: 5px;
	background: #fff;
	@media all and (max-width: 1280px) {
		width: 520px;
	}
	@media all and (max-width: 1080px) {
		width: 500px;
	}
	@media all and (max-width: 980px) {
		width: 650px;
	}
	@media all and (max-width: 768px) {
		width: 480px;
	}
	@media all and (max-width: 640px) {
		width: 400px;
	}
	@media all and (max-width: 480px) {
		width: 340px;
	}
	@media all and (max-width: 360px) {
		width: 300px;
	}
`;
const MainContainer = styled.div``;
const ImageContainer = styled.div`
	width: 30px;
	margin-right: 10px;
	img {
		display: block;
		width: 100%;
	}
`;
const Select = styled.div`
	width: 100%;
	max-height: 500px;
	overflow-y: scroll;
	padding: 20px;
	input {
		width: 100%;
		margin-bottom: 10px;
		border: 1px solid #858585 !important;
		padding: 6px 10px;
		border-radius: 4px;
	}
`;
const Option = styled.div`
	width: 100%;
	cursor: pointer;
	border: 1px solid transparent;
	margin-bottom: 10px;
	height: 45px;
	padding: 10px;
	display: flex;
	transition: 0.4s ease;
	:hover {
		border: 1px solid #0880e0;
		border-radius: 4px;
		transition: 0.4s ease;
	}
`;
const Container = styled.div`
	position: relative;
`;
const Close = styled.div`
	position: absolute;
	right: -25px;
	top: -17px;
	width: 25px;
	cursor: pointer;
	img {
		display: block;
		width: 100%;
	}
`;
const Cover = styled.div`
	max-height: 350px;
	overflow-y: scroll;
`;
